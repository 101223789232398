

.footer{

	@include breakPoint($breakPointCompact){
		font-size: 14px;
	}

	.inner{
		position: static;
	}

	&__inner{

		position: relative;

		@include breakPoint($breakPointCompact){
			width: $tabletInner;
			margin: 0 auto;
			padding: 45px 0;
		}

		@include breakPoint($breakPointWide){
			width: $desktopInner;
		}
	}

	&__logo{
		padding: 47px 0 28px;

		a{
			display: block;
			width: 308px;
			height: 122px;
			margin: 0 auto;

			@include breakPoint($breakPointCompact){
				width: 260px;
				height: 102px;
			}

			img{
				display: block;
				width: 100%;
				height: 100%;
			}
		}


		@include breakPoint($breakPointCompact){
			float: left;
			padding: 0 0 40px;
		}

		@include breakPoint($breakPointWide){
			float: left;
			padding: 0 0 30px;
		}

	}

	&__menu{
		margin: -50px 0;

		&-item{
			font-weight: 500;
			margin: 60px 0;

			@include breakPoint($breakPointCompact){
				margin: 5px 0;
			}
		}


		@include breakPoint($breakPointCompact){
			margin: 0 0 45px 380px;
		}

		@include breakPoint($breakPointWide){
			float: left;
			margin: 0 0 0 120px;
			width: 380px;
		}
	}

	&__social{


		@include breakPoint($breakPointCompact){
			margin: 0 0 0 380px;
		}

		@include breakPoint($breakPointWide){
			float: left;
			margin: 0px;
		}

		&-title{
			margin-bottom: 15px;

			@include breakPoint($breakPointCompact){
				font-weight: 500;
			}
		}

		&-item{
			width: 60px;
			height: 60px;
			display: inline-block;
			vertical-align: middle;
			background: url("../img/ico/ico-soc.svg") no-repeat 0 0;
			background-size: 100% auto;
			margin-right: 30px;

			@include breakPoint($breakPointCompact){
				width: 20px;
				height: 20px;
				margin-right: 25px;
			}

			&:last-child{
				margin-right: 0;
			}

			&_vk{
				background-position: 0 0;
			}
			&_fb{
				background-position: 0 33.33333%;
			}
			&_yt{
				background-position: 0 66.66666%;
			}
			&_ig{
				background-position: 0 100%;
			}
		}
	}


	&__copy{
		color: $colorGrey;
		padding: 35px 0;

		@include breakPoint($breakPointCompact){
			padding: 0;
			clear: both;
		}
	}

	&__politycs{
		color: $colorGrey;
		display: none;

		@include breakPoint($breakPointCompact){
			display: block;
		}
	}


	&__group{
		margin-bottom: -1px;
		border-top: 1px solid $colorBorder;
		border-bottom: 1px solid $colorBorder;

		@include breakPoint($breakPointCompact){
			margin: 0;
			border: 0;
			display: inline;
			position: static;

			.inner{
				margin: 0;
				border: 0;
				display: inline;
				position: static;
			}
		}

		&-title{
			color: $colorGrey;
			padding: 25px 45px;
			line-height: 1;
			position: relative;

			@include breakPoint($breakPointCompact){
				display: none;
			}

			&:before,
			&:after{
				content: '';
				width: 4px;
				height: 26px;
				background: #b7b7b7;
				position: absolute;
				top: 50%;
				margin-top: -13px;
				margin-right: -2px;
				right: 45px;
				@include transition();
				@include border-radius(10px);

				@include breakPoint($breakPointCompact){
					display: none;
				}
			}

			&:before{
				@include transform(rotate(-135deg));
				margin-right: -8px;
			}

			&:after{
				@include transform(rotate(135deg));
				margin-right: 8px;
			}
		}

		&-dropdown{
			display: none;
			padding: 35px 0;

			@include breakPoint($breakPointCompact){
				display: inline;
				padding: 0;
				margin: 0;
				position: static;
			}
		}

		&.is-open{
			.footer__group-dropdown{
				display: block;
			}
			.footer__group-title{
				&:before{
					@include transform(rotate(-45deg));
					margin-right: -8px;
				}

				&:after{
					@include transform(rotate(45deg));
					margin-right: 8px;
				}
			}
		}
	}


	&__address{
		padding-left: 75px;

		@include breakPoint($breakPointCompact){
			padding: 0;
			width: 320px;
			position: absolute;
			top: 45px;
			right: 0;
		}

		&-item{
			position: relative;
			margin: 50px 0;

			@include breakPoint($breakPointCompact){
				margin: 0 0 13px;
			}

			&:before{
				content: '';
				display: block;
				width: 60px;
				height: 57px;
				top: -12px;
				left: -75px;
				position: absolute;

				@include breakPoint($breakPointCompact){
					width: 26px;
					height: 25px;
					top: -8px;
					left: -40px;
				}
			}

			&:nth-of-type(1){

				&:before{
					@include backgroundSprite(214, 0);

					@include breakPoint($breakPointCompact){
						@include backgroundSprite(214, 70);
					}
				}
			}

			&:nth-of-type(2){

				&:before{
					@include backgroundSprite(293, 0);

					@include breakPoint($breakPointCompact){
						@include backgroundSprite(293, 70);
					}
				}
			}
		}

		&-title{
			font-weight: 500;
			margin-bottom: 13px;
		}

		&-info{

			@include breakPoint($breakPointCompact){
				display: inline-block;
				vertical-align: top;
				width: 145px;
			}
		}

		&-subtitle{
			font-size: 22px;
			color: $colorGrey;
			margin-bottom: 13px;

			@include breakPoint($breakPointCompact){
				font-size: 12px;
			}
		}

		&-phone{
			margin-bottom: 5px;
		}
	}
}





















