


.photos{
	&__list{
		font-size: 0;
		margin: -22px;

		@include breakPoint($breakPointCompact){
			margin: -10px;
		}

	}
	&__item{
		display: inline-block;
		width: 100%;
		@include box-sizing();
		padding: 22px;
		font-size: 24px;
		vertical-align: top;

		@include breakPoint($breakPointCompact){
			padding: 10px;
			font-size: 14px;
			width: 379px;
		}

		&-inner{
			@include border-radius(4px);
			background: #fff;
			position: relative;
			@include box-sizing();
			overflow: hidden;
			display: block;


			@include breakPoint($breakPointCompact){
				&:before{
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba($colorBlue, 0.5);
					opacity: 0;
					@include transition();
					z-index: 1;
					pointer-events: none;
				}

				&:after{
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: 44px;
					height: 44px;
					margin-top: -22px;
					margin-left: -22px;
					background: url("../img/ico/zoom.svg") no-repeat 50% 50%;
					background-size: contain;
					opacity: 0;
					@include transition();
					z-index: 1;
					pointer-events: none;
				}
			}

			&:hover{
				&:before,
				&:after{
					opacity: 1;
				}
			}

		}
		&-image{
			//width: 100%;
			font-size: 0;
			@include border-radius(4px 4px 0 0);

			width: 100%;
			height: 350px;
			overflow: hidden;

			margin: 0;
			position: relative;
			background: no-repeat 50% 50%;
			background-size: cover;

			img{
				width: 100%;
				height: 100%;
				display: block;
				object-fit: cover;
			}

			&.object-fit{

				img{
					opacity: 0;
				}
			}


			@include breakPoint($breakPointCompact){
				height: 230px;
			}
		}

	}

}
