


.events{
	&__list{
		font-size: 0;
		margin: -22px;

		@include breakPoint($breakPointCompact){
			margin: -10px;
		}

	}
	&__item{
		display: inline-block;
		width: 100%;
		@include box-sizing();
		padding: 22px;
		font-size: 24px;
		vertical-align: top;

		@include breakPoint($breakPointCompact){
			padding: 10px;
			font-size: 14px;
			width: 379px;
		}

		&-inner{
			@include border-radius(4px);
			background: #fff;
			position: relative;
			@include box-sizing();
			overflow: hidden;

			@include box-shadow(0 0 0 rgba(0,0,0,0));
			@include transition();

			&:hover{
				@include box-shadow(0 0 30px rgba(0,0,0,0.3));
			}

			@include breakPoint(0, $breakPointCompact - 1px){

				.slick-slide &{
					min-height: 540px;
				}
			}

			@include breakPoint($breakPointCompact){
				height: 340px;
			}

			&:hover{

			}
		}
		&-image{
			//width: 100%;
			font-size: 0;
			@include border-radius(4px 4px 0 0);

			width: 100%;
			height: 300px;
			overflow: hidden;

			margin: 0;
			position: relative;

			@include breakPoint($breakPointCompact){
				height: 196px;
			}


			img{
				width: 100%;
			}

			.events__item-date{
				position: absolute;
				left: 0;
				top: 0;

			}
		}

		&-info{
			padding: 0 22px;
			margin: 36px 0;

			@include breakPoint($breakPointCompact){
				padding: 0 20px;
				margin: 20px 0 24px;
			}
		}

		&-category{
			font-size: 24px;
			color: $colorGrey;
			margin-bottom: 10px;

			@include breakPoint($breakPointCompact){
				font-size: 12px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

		}

		&-title{
			font-size: 36px;
			font-weight: 500;
			width: 100%;
			max-height: 130px;

			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
				max-height: 72px;
			}

			a{
				color: inherit;
			}
		}

		&-date{
			padding-top: 15px;

			span{
				font-size: 20px;
				font-weight: 500;
				color: #fff;
				background: $colorRed;
				padding: 0 22px;
				height: 44px;
				line-height: 44px;
				@include border-radius(0 4px 4px 0);
				display: inline-block;

				@include breakPoint($breakPointCompact){
					font-size: 18px;
					height: 28px;
					line-height: 28px;
					padding: 0 12px;
					@include border-radius(0 2px 2px 0);
				}
			}
		}

	}

}
