


.ico-rub{
	display: inline-block;
	position: relative;
	font-style: normal;

	&:before{
		content: 'Р';
		color: currentColor;
	}
	&:after{
		content: '';
		position: absolute;
		top: 60%;
		left: 0;
		right: 0;
		height: 0.1em;
		@include border-radius(0.03em);
		background: currentColor;
	}
}


body {
	font-family: 'Fira Sans', sans-serif;
	font-size: 30px;
	width: 100%;
	height: 100%;
	min-width: $minWidth;
	line-height: 1.2;
	color: $colorBlack;

	@include breakPoint($breakPointCompact){
		font-size: 18px;
	}

	&.is-menu-open{
		overflow: hidden;
	}
}

.wrapper {
	height: 100%;

	.is-blur &{
		filter: blur(5px);
	}
}

.btn{
	display: block;
	background: $colorBlue;
	color: #fff;
	text-decoration: none;
	text-align: center;
	@include border-radius(4px);
	cursor: pointer;
	font-size: 24px;
	font-weight: 500;
	padding: 17px 15px;
	border: 2px solid $colorBlue;
	@include box-sizing();
	line-height: 1;

	@include breakPoint($breakPointCompact){
		display: inline-block;
		font-size: 14px;
		padding: 14px 23px 12px;
		@include border-radius(4px);
		//border-width: 1px;
	}

	&:hover{
		background: $colorBlueHover;
		border-color: $colorBlueHover;
		color: #fff;
	}

	&_blue{
		color: #fff;
		background: $colorBlue;
	}

	&_red{
		border-color:$colorRed;

		&:hover{
			background: $colorRed;
			border-color: $colorRed;
			color: #fff;
		}

	}

	&_border{
		background: #fff;
		color: $colorBlack;
	}
}

.inner{
	width: $minInner;
	margin: 0 auto;
	@include box-sizing();
	position: relative;

	@include breakPoint($breakPointCompact){
		width: $tabletInner;
	}

	@include breakPoint($breakPointWide){
		width: $desktopInner;
	}


	.popup &{
		width: 100%;
	}
	#content &{
		width: $minInner;

		@include breakPoint($breakPointCompact){
			width: $contentInner;
		}
	}
}

.title-more{
	position: absolute;
	top: 0;
	right: 0;

	.btn{

		padding: 0;
		width: 62px;
		color: transparent;
		height: 62px;
		font-size: 0;
		background: url("../img/ico/arrow-next.svg") no-repeat 0 0;
		background-size: cover;
		border: 2px solid $colorRed;

		&:hover{
			//background-color: $colorRed;
		}

		@include breakPoint($breakPointCompact){
			//border-width: 1px;
			background: transparent;
			width: auto;
			font-size: 14px;
			font-weight: 500;
			color: $colorBlack;
			padding: 0 20px;
			height: 42px;
			line-height: 40px;

			&:hover{
				background-color: $colorRedHover;
				color: #fff;
			}
		}

	}
}


.ico{
	display: inline-block;

	&-metro{
		width: 30px;
		height: 30px;
		@include border-radius(50%);
		@include backgroundSprite(88, 384);

		@include breakPoint($breakPointCompact){
			width: 17px;
			height: 17px;
			@include backgroundSprite(95, 424);
		}

		&_4{
			background-color: $colorOrange;
		}
	}

	&-walk{
		background-image: url("../img/ico/ico_walk.svg");
		width: 18px;
		height: 28px;
		margin-top: -7px;
		background-repeat: no-repeat ;
		background-position: 50% 50%;
		background-size: contain;

		@include breakPoint($breakPointCompact){
			width: 9px;
			height: 14px;
			margin-top: -2px;
		}
	}
	&-transport{
		background-image: url("../img/ico/ico_transport.svg");
		width: 23px;
		height: 26px;
		margin-top: -7px;
		background-repeat: no-repeat ;
		background-position: 50% 50%;
		background-size: contain;

		@include breakPoint($breakPointCompact){
			width: 12px;
			height: 13px;
			margin-top: -2px;
		}
	}
}


.breadcrumbs{
	&__item{
		display: inline-block;
		vertical-align: middle;
		font-size: 24px;

		@include breakPoint($breakPointCompact){
			font-size: 14px;
		}
	}

	a{
		color: $colorGrey;

		&:after{
			content: '';
			display: inline-block;
			vertical-align: middle;
			margin-right: 15px;
			margin-left: 15px;
			width: 10px;
			background: url("../img/ico/arrow-breadcrumbs.svg") no-repeat 50% 50%;
			background-size: contain;
			height: 12px;

			@include breakPoint($breakPointCompact){
				content: ' - ';
				margin-right: 10px;
				margin-left: 10px;
				background: none;
				width: auto;
				height: auto;
			}
		}
	}
}

.section{
	padding: 60px 0;

	.popup_content &,
	#content &{
		padding: 40px 0;
	}

	&:after{
		content: '';
		display: table;
		clear: both;
	}

	h1, .h1{
		font-size: 48px;
		font-weight: 600;
		color: $colorBlack;
		margin: 0 0 45px;

		@include breakPoint($breakPointCompact){
			font-size: 40px;
			margin: 0 0 35px;
		}
	}

	h2, .h2{
		font-size: 48px;
		font-weight: 600;
		margin: 0 0 35px;
		padding-right: 100px;
		color: $colorRed;

		@include breakPoint($breakPointCompact){
			font-size: 40px;
			margin: 0 0 35px;
			padding-right: 200px;
		}
	}

	&_share{
		padding-top: 0;
		.popup_content &,
		#content &{
			padding-top: 0;
		}
	}

	&_poster,
	&_clubmap,
	&_content,
	&_trainer{
		padding: 0;

		#content &{
			padding: 0;
		}
	}

	&_breadcrumbs{
		padding: 30px 0;

		@include breakPoint($breakPointCompact){
			font-size: 14px;
			background: $colorLight;
			padding-bottom: 30px;
			//margin-bottom: -50px;

			& + .section{
				padding-top: 0;
			}
		}

		.popup_content &,
		#content & {
			@include breakPoint($breakPointCompact){
				padding: 30px 0;
				margin: 0;
			}
		}
	}

	&_text{


	}

	&_map{

		position: relative;
		padding: 0;

		.map__image{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			background: #dfdfdf no-repeat 50% 0;
			background-size: cover;


			@include breakPoint($breakPointCompact){
				background-position: 0 50%;
				background-size: 120% auto;
			}

			@include breakPoint($breakPointWide){
				background-position: 50% 50%;
				background-size: cover;
			}
		}
		
	}

	&_slider{
		overflow: hidden;
	}


	&_filter{
		padding: 22px 0;
	}

	h3{
		font-size: 24px;
		font-weight: 400;
		color: $colorGrey;
		margin: 40px 0 30px;

		@include breakPoint($breakPointCompact){
			font-size: 14px;
			margin: 20px 0 15px;
		}
	}

	p{

	}

	ul{
		margin-bottom: 15px;


	}

	li{
		padding-left: 28px;
		margin-bottom: 12px;
		position: relative;

		@include breakPoint($breakPointCompact){
			padding-left: 15px;
		}

		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 14px;
			left: 0;
			width: 9px;
			height: 9px;
			@include border-radius(50%);
			background: $colorBlack;

			@include breakPoint($breakPointCompact){
				width: 5px;
				height: 5px;
				top: 7px;
			}
		}
	}
}


a{
	color: $colorBlue;
}

.footnote{
	font-size: 20px;
	color: $colorGrey;
}

.more{
	text-align: center;
	font-size: 36px;
	color: $colorGrey;


	span{
		display: inline-block;
		cursor: pointer;

		&:after{
			content: '';
			display: inline-block;
			vertical-align: middle;
			margin-left: 25px;

			width: 40px;
			height: 40px;
			background: url("../img/ico/arrows.svg") no-repeat 0 0;
			background-size: auto 100%;
			@include transform(rotate(-90deg));
			margin-top: -10px;
		}
	}
}


@import "section/education";


.trainer{

	position: relative;
	overflow: hidden;


	@include breakPoint($breakPointCompact){
		height: 435px;
	}

	&__info{
		margin-bottom: -220px;
		position: relative;
		color: #fff;
		z-index: 1;

		@include breakPoint($breakPointCompact){
			margin-bottom: 0;
			height: 100%;
		}

		.inner{
			position: relative;
			z-index: 1;
			padding: 110px 0;

			@include breakPoint($breakPointCompact){
				@include box-sizing();
				height: 100%;
				padding-bottom: 0;
			}
		}

		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 220px;
			background: $colorBlue;

			@include breakPoint($breakPointCompact){
				bottom: 0;
				width: (100% / 3);
			}
		}
		&:after{
			content: '';
			height: 225px;
			position: absolute;
			left: 0;
			width: 100%;
			bottom: 0;
			background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwNmRiOCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDZkYjgiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
			background: -moz-linear-gradient(top, rgba(0,109,184,1) 0%, rgba(0,109,184,0) 100%);
			background: -webkit-linear-gradient(top, rgba(0,109,184,1) 0%,rgba(0,109,184,0) 100%);
			background: linear-gradient(to bottom, rgba(0,109,184,1) 0%,rgba(0,109,184,0) 100%);



			@include breakPoint($breakPointCompact){
				background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwNmRiOCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDZkYjgiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
				background: -moz-linear-gradient(left, rgba(0,109,184,1) 0%, rgba(0,109,184,0) 100%);
				background: -webkit-linear-gradient(left, rgba(0,109,184,1) 0%,rgba(0,109,184,0) 100%);
				background: linear-gradient(to right, rgba(0,109,184,1) 0%,rgba(0,109,184,0) 100%);
				left: (100% / 3);
				width: (100% / 3);
				height: 100%;
			}
		}

		&-rank{
			margin-bottom: 20px;

			@include breakPoint($breakPointCompact){

			}
		}
		&-name{
			font-size: 72px;
			font-weight: 600;
			margin-bottom: 30px;

			@include breakPoint($breakPointCompact){
				font-size: 60px;
				margin-bottom: 16px;
			}
		}
		&-place{
			font-size: 24px;
			margin-bottom: 30px;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
				margin-bottom: 80px;
			}

			&-item{
				padding-left: 50px;
				position: relative;
				margin-bottom: 20px;

				@include breakPoint($breakPointCompact){
					padding-left: 30px;
					margin-bottom: 10px;
				}
			}
			&-ico{
				position: absolute;
				top: -2px;
				left: 0;
			}
			&-address{}
		}
		&-plate{
			font-size: 18px;
			color: $colorBlack;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
			}

			&-item{
				background: #cee3f1;
				display: inline-block;
				padding: 13px 30px;
				text-align: center;
				@include border-radius(5px);
				margin: 0 21px 21px 0;
				cursor: default;

				@include breakPoint($breakPointCompact){
					padding: 10px 19px 8px;
					margin: 0 15px 15px 0;
				}
			}
		}
	}

	&__photo{
		height: 550px;
		background: no-repeat 50% 50%;
		background-size: cover;

		@include breakPoint($breakPointCompact){
			height: 100%;
			position: absolute;
			top: 0;
			left: 30%;
			width: 70%;
		}
	}
}

.poster{

	position: relative;
	overflow: hidden;
	background: $colorGrey no-repeat 50% 50%;
	background-size: cover;

	min-height: 520px;


	@include breakPoint($breakPointCompact){
		min-height: 280px;
	}

	&__info{
		position: relative;
		color: #fff;
		z-index: 1;

		@include breakPoint($breakPointCompact){
			margin-bottom: 0;
			height: 100%;
		}

		.inner{
			position: relative;
			z-index: 1;
			padding: 65px 0;
			@include box-sizing();
			min-height: 530px;

			@include breakPoint($breakPointCompact){
				padding: 45px 0;
				min-height: 280px;
			}
		}

		&-rank{
			margin-bottom: 20px;

			@include breakPoint($breakPointCompact){
				margin-bottom: 10px;
			}
		}
		&-name{
			font-size: 72px;
			font-weight: 600;
			margin-bottom: 30px;

			@include breakPoint($breakPointCompact){
				font-size: 60px;
				margin-bottom: 16px;
			}
		}
		&-place{
			font-size: 24px;
			margin-bottom: 35px;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
				margin-bottom: 20px;

			}

			&-item{
				padding-left: 50px;
				position: relative;
				margin-bottom: 35px;

				@include breakPoint($breakPointCompact){
					padding-left: 27px;
					margin-bottom: 10px;
					display: inline-block;
					margin-right: 15px;
				}
			}
			&-ico{
				position: absolute;
				top: -2px;
				left: 0;
			}
			&-address{
				display: inline-block;
				vertical-align: middle;
			}

			&-path{
				display: inline-block;
				vertical-align: middle;
				color: $colorBlue;
				margin-left: 20px;

				@include breakPoint($breakPointCompact){
					margin-left: 10px;
				}

				i{
					margin-right: 7px;
					display: inline-block;
					vertical-align: middle;
					background-repeat: no-repeat ;
					background-position: 50% 50%;
					background-size: contain;

					@include breakPoint($breakPointCompact){
						margin-right: 5px;
					}
				}


			}
		}
		&-plate{
			font-size: 18px;
			color: $colorBlack;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
			}

			&-item{
				background: rgba($colorBlue, 0.8);
				display: inline-block;
				color: #fff;
				padding: 13px 30px;
				text-align: center;
				@include border-radius(5px);
				margin: 0 21px 21px 0;
				cursor: default;

				@include breakPoint($breakPointCompact){
					padding: 10px 19px 8px;
					margin: 0 15px 15px 0;
				}
			}
		}
	}

	&__photo{
		height: 550px;
		background: no-repeat 50% 50%;
		background-size: cover;

		@include breakPoint($breakPointCompact){
			height: 100%;
			position: absolute;
			top: 0;
			left: 30%;
			width: 70%;
		}
	}
}


.section_about{

	ul{
		margin: 0;

		@include breakPoint($breakPointCompact){
			width: (100% / 3);
			float: left;
		}

		@include breakPoint($breakPointWide){
			width: (100% / 4);
		}

		li{
			@include breakPoint($breakPointCompact){
				font-size: 14px;
			}
			&:before{
				background: $colorRed;

				@include breakPoint($breakPointCompact){
					top: 5px;
				}
			}
		}
	}


	.contact{
		margin-top: 90px;

		@include breakPoint($breakPointCompact){
			margin-top: 45px;
		}


		&__title{
			font-size: 40px;
			font-weight: 500;
			margin-bottom: 70px;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
				margin-bottom: 35px;
			}

		}

		&__item{
			margin-bottom: 70px;
			padding-left: 70px;
			position: relative;

			@include breakPoint($breakPointCompact){
				display: inline-block;
				vertical-align: top;
				width: 280px;
				margin-right: 45px;
				padding-left: 50px;
			}

			&-ico{
				position: absolute;
				top: 0;
				left: 0;

				&_phone{
					width: 50px;
					height: 50px;
					@include backgroundSprite(76, 71);
					position: absolute;
					top: -2px;
					left: 0;

					@include breakPoint($breakPointCompact){
						width: 28px;
						height: 28px;
						top: -4px;
						@include backgroundSprite(134, 85);
					}

					@include breakPoint($breakPointWide){
						top: 2px;
					}
				}

				&_address{
					width: 50px;
					height: 50px;
					@include backgroundSprite(76, 5);
					position: absolute;
					top: -5px;
					left: 0px;

					@include breakPoint($breakPointCompact){
						width: 27px;
						height: 34px;
						top: -10px;
						@include backgroundSprite(133, 21);
					}

					@include breakPoint($breakPointWide){
						top: -5px;
					}
				}
			}


			&-title{
				font-size: 24px;
				font-weight: 500;
				margin-bottom: 15px;

				@include breakPoint($breakPointCompact){
					font-size: 14px;
					margin-bottom: 12px;
				}

			}
			&-text{
				font-size: 24px;
				font-weight: 300;
				margin-bottom: 30px;

				@include breakPoint($breakPointCompact){
					font-size: 14px;
					margin-bottom: 20px;
					line-height: 1.5;
				}
			}
			&-detail{
				font-size: 30px;
				font-weight: 500;
				color: $colorRed;

				@include breakPoint($breakPointCompact){
					font-size: 20px;
				}
			}
		}


	}



	h3{

		@include breakPoint($breakPointCompact){
			margin-top: 50px;
		}
	}
}

@import "section/achievements";

@import "section/specificity";

@import "section/gallery";

.tabs{

	border-bottom: 1px solid $colorBorder;
	overflow: hidden;
	margin-bottom: 50px;

	@include breakPoint($breakPointCompact){
		//overflow: visible;
	}

	.slick{
		&-slider{

		}
		&-list{
			overflow: hidden!important;

			&:after{
				content: '';
				position: absolute;
				width: 30px;
				height: 100%;
				top: 0;
				right: 0;

				background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2YyZjJmMiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmMmYyZjIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
				background: -moz-linear-gradient(left, rgba($colorLight,0) 0%, rgba($colorLight,1) 100%);
				background: -webkit-linear-gradient(left, rgba($colorLight,0) 0%,rgba($colorLight,1) 100%);
				background: linear-gradient(to right, rgba($colorLight,0) 0%,rgba($colorLight,1) 100%);
			}
		}

		&-slide{

		}
	}

	.slider-control__arrow{

		top: 4px;

		&_prev{
			left: -60px;
		}
		&_next{
			right: -60px;
		}
	}

	&__menu{
		white-space: nowrap;
		overflow-x: auto;
		margin-bottom: -20px;
		padding-bottom: 20px;

		@include breakPoint($breakPointCompact){
			//overflow: visible;
			//margin-bottom: 0px;
			//padding-bottom: 0px;
			//white-space: normal;
		}

		&-item{
			display: inline-block;
			cursor: pointer;
			color: $colorGrey;
			padding-right: 60px;
			@include user-select();
			@include box-sizing();

			@include breakPoint($breakPointCompact){
				padding-right: 50px;
				font-size: 18px;

				.tabs_calendar &{
					padding-top: 15px;
				}

			}

			&:hover{
				color: $colorBlack;
			}


			&.is-active{
				color: $colorBlack;
				font-weight: 500;

				span{
					border-bottom: 5px solid $colorRed;
				}
			}

			span{
				display: inline-block;
				padding-bottom: 20px;
				position: relative;


				sup{
					font-size: 20px;
					color: inherit;
					left: 0;
					bottom: 100%;
					display: block;

					@include breakPoint($breakPointCompact){
						font-size: 14px;
						position: absolute;
						left: 0;
						bottom: 100%;
					}
				}
			}
		}

		&-btn{
			width: 100%;

			.btn{
				display: block;
			}

			@include breakPoint($breakPointCompact){
				float: right;
				width: auto;
			}
		}
	}

}

.section_menu{
	padding: 0;
	background: $colorLight;
	height: $headerHeightMobile;

	@include breakPoint($breakPointCompact){
		background: none;
		height: $headerHeightDesktop;
	}

	.tabs{
		border-bottom: none;
		background: #fff;
		height: ($headerHeightMobile + 2px);

		@include breakPoint($breakPointCompact){
			height: $headerHeightDesktop;
		}

		&.is-fixed{
			position: fixed;
			left: 0;
			width: 100%;
			top: $headerHeightMobile;
			z-index: 10;
			@include box-shadow(0 10px 10px -10px rgba(0,0,0,0.35));

			@include breakPoint($breakPointCompact){
				top: $headerHeightDesktop;
			}
		}

		&__menu{

			padding-top: 30px;
			padding-bottom: 30px;

			&-item{
				//display: none;

				@include breakPoint($breakPointCompact){
					display: inline-block;
					float: left;
					font-size: 14px;
					padding-right: 20px;
				}
			}

			&-btn{
				display: none;

				@include breakPoint($breakPointCompact){
					display: block;
					float: right;
					margin-top: -18px;
				}
			}
		}

	}
}



@import "section/cards";

@import "section/appointment";

@import "section/schedule";

@import "section/trainers";

@import "section/member";

@import "section/announce";

@import "section/events";

@import "section/filter";

@import "section/photos";

@import "section/services";


.pagination{
	margin-top: 60px;
	@include user-select();

	&__list{
		font-size: 0;
		margin: 20px -11px;

		@include breakPoint($breakPointCompact){
			margin: 0 -7px;
			display: inline-block;
			vertical-align: top;
		}
	}
	&__item{
		font-size: 20px;
		display: inline-block;
		@include border-radius(8px);
		background: #fff;
		color: $colorBlack;
		text-align: center;
		vertical-align: middle;
		line-height: 62px;
		height: 66px;
		width: 66px;
		border: 2px solid $colorLight;
		margin: 11px;
		@include box-sizing();
		font-weight: 500;
		cursor: pointer;

		@include breakPoint($breakPointCompact){
			font-size: 14px;
			height: 42px;
			width: 42px;
			line-height: 40px;
			border-width: 1px;
			margin: 7px;
			@include border-radius(4px);
		}

		&:hover{
			background-color: $colorRed;
			color: #fff;
		}

		&.is-active{
			border-color: $colorRed;
			@include box-shadow(inset 0 0 0 1px $colorRed);
			//background-color: $colorRed;
			//color: #fff;
		}
	}

	input{
		opacity: 0;
		position: absolute;

		&:checked ~ .pagination__item{
			border-color: $colorRed;
			@include box-shadow(inset 0 0 0 1px $colorRed);
		}
	}

	&__dots{
		font-size: 14px;
		font-weight: 500;
		display: inline-block;
		vertical-align: bottom;
		width: 44px;
		text-align: center;
		padding-bottom: 10px;
	}


	&__control{
		font-size: 0;
		margin: 20px -11px;

		@include breakPoint($breakPointCompact){
			margin: 0 2px;
			display: inline-block;
			vertical-align: top;
		}

		.btn{
			margin: 11px;
			display: inline-block;
			vertical-align: middle;
			background: #fff no-repeat 0 0;
			background-size: 100% auto;
			color: $colorBlack;
			border: 2px solid transparent;
			width: 264px;
			@include box-sizing();

			&:hover{
				background-color: $colorRed;

				@include breakPoint($breakPointCompact){
					margin: 7px;
					border-width: 1px;
					padding: 0;
					width: 42px;
					color: transparent;
					height: 42px;
					font-size: 0;
					background-position: 0 100%;
				}
			}

			@include breakPoint($breakPointCompact){
				margin: 7px;
				border-width: 1px;
				padding: 0;
				width: 42px;
				color: transparent;
				height: 42px;
				font-size: 0;

				&_prev{
					background-image: url("../img/ico/arrow-prev.svg");
				}

				&_next{
					background-image: url("../img/ico/arrow-next.svg");
				}
			}

			&.is-disabled{
				border-color: #e5e5e5!important;
				color: #c4c5c6;

				@include breakPoint($breakPointCompact){
					display: none;
				}
			}
		}
	}
}

.js-schedule-slider,
.js-mobile-slider,
.js-adaptive-slider{

	.slick-slide{

		@include breakPoint(0, $breakPointCompact - 1px){
			max-width: 594px;
		}
	}

	//
}

.js-content-slider,
.js-block-slider,
.js-adaptive-slider{

	& ~ .slider-control{
		text-align: center;

		@include breakPoint($breakPointCompact){
			display: block;
		}

		.slider-control{
			&__count{
				display: inline-block;
				width: 270px;
				vertical-align: middle;

				@include breakPoint($breakPointCompact){
					display: none;
				}
			}

			&__arrow{
				display: inline-block;
				vertical-align: middle;
				position: static;
			}

			&__dots{
				display: inline-block;
				vertical-align: middle;
				max-width: calc(100% - 200px);
			}
		}



		.slick{

			&-dots{
				position: relative;
				text-align: center;
				margin: 0 40px;
				font-size: 0;
				display: none;

				@include breakPoint($breakPointCompact){
					display: inline-block;
				}

				li{
					display: inline-block;
					margin: 9px;
					padding: 0;

					&:before{
						width: 8px;
						height: 8px;
						@include border-radius(50%);
						background: #ccc;
						position: static;
						border: 2px solid #ccc;
					}

					&.slick-active{
						&:before{
							border-color: $colorRed;
							background: transparent;
						}
					}

					&:hover{
						&:before{
							border-color: $colorRed;
							background: $colorRed;
						}
					}
				}
			}
		}
	}
}


.is-hidden{
	display: none !important;
}





.map{

	padding: 330px 0 85px;

	@include breakPoint($breakPointCompact){
		padding: 45px 0;
	}

	&__info{
		background: #fff;
		padding: 30px 22px;
		@include border-radius(4px);
		max-width: 630px;

		@include breakPoint($breakPointCompact){
			padding: 20px 25px 10px;
		}

		h2{

			@include breakPoint($breakPointCompact){
				margin-bottom: 10px;
			}
		}
	}



	&__item{
		position: relative;
		border-bottom: 1px solid $colorBorder;
		min-height: 75px;

		@include breakPoint($breakPointCompact){
			min-height: 40px;
		}


		&:last-child{
			border-bottom: 0;
		}

		&-icon{

			width: 50px;
			position: absolute;
			top: 15px;
			left: 0;

			@include breakPoint($breakPointCompact){
				width: 25px;
				top: 7px;
			}

			img{
				width: 100%;
			}
		}

		&-info{
			padding: 5px 0 5px 68px;
			overflow: hidden;
			font-size: 24px;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
				padding: 5px 0 5px 40px;
			}

			p{
				margin: 10px 0;

				@include breakPoint($breakPointCompact){
					margin: 5px 0;
				}
			}

			.ico{
				vertical-align: middle;
				margin: 5px 10px 5px 0;

				@include breakPoint($breakPointCompact){
					margin: 2px 5px 2px 0;
				}
			}

			.text{
				display: inline-block;
				vertical-align: middle;
				margin-right: 25px;

				@include breakPoint($breakPointCompact){
					margin-right: 15px;
				}
			}

			.path{
				color: $colorBlue;
				margin-right: 25px;
				display: inline-block;
				vertical-align: middle;
				white-space: nowrap;

				@include breakPoint($breakPointCompact){
					margin-right: 15px;
				}
			}

			.title{
				display: block;
				vertical-align: middle;
				color: $colorBorder;
			}

			.float{
				display: inline-block;

				@include breakPoint($breakPointCompact){
					margin-right: 25px;
				}
			}
		}


	}
}



.contacts{

	font-size: 48px;
	margin-top: 60px;

	@include breakPoint($breakPointCompact){
		margin-top: 40px;
	}



	&__item{
		padding-left: 65px;
		margin-bottom: 35px;
		position: relative;

		@include breakPoint($breakPointCompact){
			padding-left: 50px;
			margin-bottom: 40px;
		}

		&-case{

			@include breakPoint($breakPointCompact){
				display: inline-block;
				vertical-align: top;
				margin-right: 20px;

				.contacts__item-phone{
					//display: inline-block;
					margin-right: 20px;
					float: left;
				}
			}
		}


		&-ico{
			width: 50px;
			height: 50px;
			position: absolute;
			top: -2px;
			left: 0;

			@include breakPoint($breakPointCompact){
				width: 28px;
				height: 28px;
				top: -4px;
			}

			&_phone{
				@include backgroundSprite(76, 71);

				@include breakPoint($breakPointCompact){
					@include backgroundSprite(134, 85);
				}
			}

			&_city{
				@include backgroundSprite(76, 5);
				top: -11px;

				@include breakPoint($breakPointCompact){
					height: 34px;
					@include backgroundSprite(133, 21);
				}
			}

			&_time{
				@include backgroundSprite(78, 454);
				top: -11px;

				@include breakPoint($breakPointCompact){
					height: 34px;
					top: -8px;
					@include backgroundSprite(134, 472);
				}
			}

		}

		&-title{
			font-size: 30px;
			font-weight: 500;
			margin-bottom: 10px;

			@include breakPoint($breakPointCompact){
				font-size: 18px;
				margin-bottom: 20px;
			}
		}


		&-address{
			font-size: 24px;
			text-indent: -46px;
			padding-left: 46px;

			& * {
				text-indent: 0;
			}

			@include breakPoint($breakPointCompact){
				font-size: 14px;
			}

			p{
				margin: 10px 0;

				@include breakPoint($breakPointCompact){
					margin: 5px 0;
				}
			}

			.ico{
				vertical-align: middle;
				margin: 2px 10px 8px 0;

				@include breakPoint($breakPointCompact){
					margin: 2px 5px 2px 0;
				}
			}

			.text{
				display: inline-block;
				vertical-align: middle;
				margin-right: 25px;

				@include breakPoint($breakPointCompact){
					margin-right: 15px;
				}
			}

			.path{
				color: $colorBlue;
				margin-right: 25px;
				display: inline-block;
				vertical-align: middle;
				white-space: nowrap;

				@include breakPoint($breakPointCompact){
					margin-right: 15px;
				}
			}

			//.title{
			//	display: block;
			//	vertical-align: middle;
			//	color: $colorBorder;
			//}
			//
			//.float{
			//	display: inline-block;
			//
			//	@include breakPoint($breakPointCompact){
			//		margin-right: 25px;
			//	}
			//}
		}

		&-phone{
			font-size: 30px;
			font-weight: 500;
			color: $colorRed;
			margin-top: 20px;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
				margin-top: 0px;
			}
		}

		&-text{
			font-size: 24px;
			line-height: 1.5;
			margin: 30px 0 20px;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
				margin: 0 0 5px;
			}
		}
	}



	&__map{
		height: 660px;
		margin-bottom: -60px;
		margin-top: 80px;


		@include breakPoint($breakPointCompact){
			height: 480px;
		}
	}


}


.vacancy{
	&__list{
		margin: 0 -11px;
		font-size: 0;

		@include breakPoint($breakPointCompact){
			margin: 0 -18px;
		}

		@include breakPoint($breakPointWide){
			margin: 0 -10px;
		}
	}
	&__item{
		display: inline-block;
		vertical-align: top;
		width: 100%;

		@include breakPoint($breakPointCompact){
			width: (100% / 3);
		}

		@include breakPoint($breakPointWide){
			width: (100% / 4);
		}

		&-inner{
			padding: 22px;
			margin: 11px;
			background: #fff;
			@include border-radius(8px);
			@include box-sizing();

			@include breakPoint($breakPointCompact){
				@include border-radius(4px);
				padding: 14px 20px;
				margin: 10px 18px;
			}

			@include breakPoint($breakPointWide){
				margin: 10px;
			}
		}
		&-title{
			font-size: 36px;
			min-height: 140px;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
				min-height: 65px;
			}
		}
		&-labels{
			margin: 0 -18px -18px 0;

			@include breakPoint($breakPointCompact){
				margin: 0 -7px -7px 0;
			}

			.label{
				font-size: 20px;
				font-weight: 500;
				height: 44px;
				line-height: 44px;
				padding: 0 30px;
				@include border-radius(4px);
				background: $colorRed;
				color: #fff;
				display: inline-block;
				margin: 0 18px 18px 0;

				@include breakPoint($breakPointCompact){
					height: 19px;
					line-height: 20px;
					padding: 0 11px;
					font-size: 12px;
					margin: 0 7px 7px 0;
					@include border-radius(2px);
				}
			}
		}
	}
}



.concealer{

	&.is-open{

		.concealer__back{
			display: block;
		}

		.concealer__front-handler{
			&:after{
				@include transform(rotate(0deg));
			}
		}
	}


	&__front{
		position: relative;
		margin: 30px 0;

		&:before{
			content: '';
			height: 1px;
			width: 100%;
			top: 50%;
			background: $colorBorder;
			position: absolute;
			left: 0;
		}

		.inner{
			position: relative;
		}

		&-title{
			font-size: 30px;
			background: #fff;
			padding: 22px 40px;
			display: inline-block;
			vertical-align: middle;

			@include breakPoint($breakPointCompact){
				font-size: 16px;
				padding: 11px 54px 11px 14px;
			}
		}

		&-handler{
			width: 66px;
			height: 66px;
			@include box-sizing();
			@include border-radius(8px);
			border: 2px solid $colorBorder;
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -33px;
			background: #fff;
			cursor: pointer;

			@include breakPoint($breakPointCompact){
				width: 26px;
				height: 26px;
				margin-top: 0;
				border-width: 1px;
				@include border-radius(4px);
				display: inline-block;
				vertical-align: middle;
				position: relative;
				top: 0;
				right: 0;
				margin-left: -46px;
			}

			&:before,
			&:after{
				content: '';
				width: 30px;
				height: 5px;
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -2.5px;
				margin-left: -15px;
				background: $colorBlack;

				@include breakPoint($breakPointCompact){
					width: 10px;
					height: 2px;
					margin-top: -1px;
					margin-left: -5px;
				}
			}

			&:after{
				@include transform(rotate(90deg));
			}
		}
	}

	&__back{
		display: none;
	}
}

@import "../scss/clubmap";




.content{
	font-size: 24px;

	@include breakPoint($breakPointCompact){
		font-size: 14px;
	}

	h1{
		font-size: 48px;
		font-weight: 600;

		@include breakPoint($breakPointCompact){
			font-size: 36px;
			margin-bottom: 20px;
			margin-top: 22px;
		}
	}
	h2{
		font-size: 30px;
		font-weight: 500;
		color: $colorBlack;
		margin: 40px 0;
		padding: 0;

		@include breakPoint($breakPointCompact){
			font-size: 20px;
			margin: 20px 0;
		}
	}
	h3{
		font-size: 30px;

		@include breakPoint($breakPointCompact){
			font-size: 14px;
		}
	}
	p{
		line-height: 1.5;
		margin: 20px 0;
		font-weight: 300;

		@include breakPoint($breakPointCompact){
			margin: 10px 0;
		}

		&.subtitle{
			font-weight: 500;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
				margin: 15px 0;
			}
		}
	}
	ul{

		@include breakPoint($breakPointCompact){
			//display: inline-block;
			//width: 45%;
			@include box-sizing();
			//padding-right: 30px;
			margin-bottom: 25px;
		}

		li{
			&:before{
				background-color: $colorRed;
				top: 8px;

				@include breakPoint($breakPointCompact){
					top: 5px;
				}
			}
		}
	}

	.label{
		font-size: 24px;
		display: inline-block;
		padding: 10px 28px;
		margin-right: 35px;

		@include breakPoint($breakPointCompact){
			font-size: 12px;
			padding: 3px 10px 2px;
			margin-right: 10px;
		}

		&_red{
			background: $colorRed;
			@include border-radius(2px);
			color: #fff;
		}

		&-text{
			display: inline-block;
			vertical-align: middle;
			color: $colorGrey;
			max-width: 320px;
			font-weight: 300;

			@include breakPoint($breakPointCompact){
				max-width: 400px;
			}
		}
	}


	&__label{
		margin: 30px 0 60px -45px ;

		@include breakPoint($breakPointCompact){
			margin: 20px 0 40px -30px ;
		}
		&:first-child{
			margin-top: 0;
		}

		&-item{
			background: $colorRed;
			color: #fff;
			display: inline-block;
			padding: 15px 45px;
			vertical-align: middle;
			font-size: 24px;
			margin-right: 15px;
			@include border-radius(0 4px 4px 0);

			@include breakPoint($breakPointCompact){
				font-size: 14px;
				padding: 8px 30px;

				margin-right: 10px;

				b{
					font-size: 20px;
					display: inline-block;
					vertical-align: middle;
					margin-left: 10px;
				}
			}
		}

		&-text{
			display: inline-block;
			vertical-align: middle;
			font-size: 24px;
			font-weight: 400;
			color: $colorGrey;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
				margin: 20px 0 15px;
			}
		}
	}


	&__contact{
		font-size: 48px;
		margin-top: 60px;

		@include breakPoint($breakPointCompact){
			margin-top: 40px;
		}

		&-ico{
			width: 50px;
			height: 50px;
			position: absolute;
			top: -2px;
			left: 0;

			@include breakPoint($breakPointCompact){
				width: 28px;
				height: 28px;
				top: -4px;
			}

			&_phone{
				@include backgroundSprite(76, 71);

				@include breakPoint($breakPointCompact){
					@include backgroundSprite(134, 85);
				}
			}

			&_city{
				@include backgroundSprite(76, 5);
				top: -11px;

				@include breakPoint($breakPointCompact){
					height: 34px;
					@include backgroundSprite(133, 21);
				}
			}

			&_time{
				@include backgroundSprite(78, 454);
				top: -11px;

				@include breakPoint($breakPointCompact){
					height: 34px;
					@include backgroundSprite(134, 472);
				}
			}

		}

		&-item{
			padding-left: 65px;
			margin-bottom: 35px;
			position: relative;

			@include breakPoint($breakPointCompact){
				padding-left: 50px;
				width: 50%;
				@include box-sizing();
				float: left;
				padding-right: 30px;
				margin-bottom: 0;
			}
		}

		&-h{
			font-size: 48px;
			font-weight: 600;
			margin-bottom: 25px;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
			}
		}

		&-title{
			font-size: 30px;
			font-weight: 500;
			margin-bottom: 10px;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
			}
		}

		&-phone{
			font-size: 30px;
			font-weight: 500;
			color: $colorRed;
			margin-top: 25px;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
				margin-top: 15px;
			}
		}

		&-text{
			font-size: 24px;
			font-weight: 300;
			line-height: 1.5;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
			}
		}
	}


}



.share{
	border-top: 1px solid $colorBorder;
	margin: 0 0 50px;
	padding-top: 30px;

	@include breakPoint($breakPointCompact){
		margin: 0;
	}

	&__item{
		margin-right: 40px;
		display: inline-block;
		vertical-align: middle;

		&-ico{
			width: 45px;
			height: 45px;
			display: inline-block;
			vertical-align: middle;
			background: url("../img/ico/ico-soc.svg") no-repeat 0 0;
			background-size: 100% auto;
			margin-right: 20px;

			.share__item_vk &{
				background-position: 0 0;
			}
			.share__item_fb &{
				background-position: 0 33.33333%;
			}

			@include breakPoint($breakPointCompact){
				width: 32px;
				height: 32px;
				margin-right: 25px;
			}
		}

		&:last-child{
			margin-right: 0;
		}


		&-count{
			font-size: 24px;
			display: inline-block;
			vertical-align: middle;
			color: $colorBlue;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
			}
		}
	}
}