
.services{

	@include breakPoint($breakPointCompact){

	}

	&__list{
		margin: 50px -16px 0;
		font-size: 0;
		//white-space: nowrap;

		@include breakPoint($breakPointCompact){
			margin: -10px -17px 40px;
			white-space: normal;
		}

		@include breakPoint($breakPointWide){
			margin: -10px -10px 40px;
			width: 1064px;
			float: left;
		}

	}

	&__item{
		//@include box-sizing();
		width: 100%;
		display: inline-block;
		white-space: normal;
		vertical-align: top;

		@include breakPoint(0, $breakPointCompact - 1px){


			.slick-slide &{
				width: 398px!important;
			}
		}

		@include breakPoint($breakPointCompact){
			@include box-sizing();
			width: 230px;
			margin-bottom: 20px;
		}

		@include breakPoint($breakPointWide){
			width: 212px;
		}


		&-inner{
			@include border-radius(8px);
			position: relative;
			//padding: 20px;
			margin: 16px;
			@include box-sizing();
			text-align: center;

			@include breakPoint($breakPointCompact){
				//padding: 20px 0px;
				margin: 10px 17px;
				@include border-radius(4px);
			}

			@include breakPoint($breakPointWide){
				margin: 10px;
			}
		}


		&-num{
			font-size: 200px;
			font-weight: 600;
			position: absolute;
			top: -50px;
			left: 0;
			opacity: 0.15;

			@include breakPoint($breakPointCompact){
				font-size: 100px;
				top: -20px;
			}
		}

		&-icon{
			width: 260px;
			height: 200px;
			margin: 0 auto 30px;
			position: relative;

			@include breakPoint($breakPointCompact){
				width: 130px;
				height: 100px;
				margin-bottom: 20px;
			}

			img{
				display: block;
				width: 100%;
			}
		}

		&-info{
			font-size: 24px;
			font-weight: 500;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
			}
		}
	}

	.slider-control{
		margin-top: 30px;
		margin-bottom: 80px;
	}



	&__more{

		@include breakPoint($breakPointWide){
			margin-left: 1140px;
		}

		&-title{
			font-size: 30px;
			font-weight: 500;
			margin-bottom: 30px;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
			}
		}

		&-list{
			font-size: 24px;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
			}
		}

		ul{

			@include breakPoint($breakPointCompact, $breakPointWide - 1px){
				float: left;
				width: 33%;
			}
		}

		li{
			&:before{
				background: $colorRed;
				width: 6px;
				height: 6px;
				top: 12px;
			}

			@include breakPoint($breakPointCompact){
				padding-left: 20px;

				&:before{
					top: 5px;
				}
			}
		}
	}
}

