.section_member{

}



.member{
	&__list{
		font-size: 0;
		margin: 0 -22px;

		&.slick-slider{
			&:hover{
				z-index: 1;
			}
		}

		@include breakPoint($breakPointCompact){
			margin: 0 -10px;
		}


		.slick-list{
			padding-bottom: 50px;
		}


	}


	&__item{


		display: inline-block;
		width: 100%;
		@include box-sizing();
		padding: 17px;
		font-size: 24px;
		vertical-align: top;

		@include breakPoint($breakPointCompact){
			padding: 10px;
			font-size: 14px;
			width: 379px;
		}


		.popup_content &,
		#content &{
			width: 100%;
		}

		//.slick-slide &{
		//	@include breakPoint(0, $breakPointCompact - 1px){
		//		width: 406px!important;
		//	}
		//}


		&-inner{
			border: 1px solid $colorBorder;
			@include border-radius(4px);
			padding: 22px;
			position: relative;
			@include box-sizing();

			@include breakPoint($breakPointCompact){
				padding: 10px;
			}

			&:hover{
				border-color: $colorBlue;
				z-index: 2;

				.member__item-action{
					display: block;
					border-color: $colorBlue;
				}
			}
		}
		&-image{
			width: 330px;
			height: 210px;
			font-size: 0;
			@include border-radius(10px);
			overflow: hidden;
			@include box-shadow(0 0 30px rgba(0,0,0,0.3));
			margin: 0 auto 25px;
			position: relative;
			max-width: 100%;

			@include breakPoint($breakPointCompact){

			}

			.popup_content &,
			#content &{
				width: 545px;
				height: auto;
				margin: 50px auto;
			}

			.member__item_action &{
				&:before{
					content: 'АКЦИЯ';
					background: $colorRed;
					color: #fff;
					font-weight: 500;
					font-size: 20px;
					padding: 10px 15px;
					position: absolute;
					top: 14px;
					left: 0;

					@include breakPoint($breakPointCompact){
						top: 13px;
						padding: 7px 10px;
						font-size: 12px;
					}
				}
			}

			img{
				width: 100%;
				height: 100%;
			}
		}
		&-title{
			font-size: 36px;
			font-weight: 500;
			margin-bottom: 35px;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
			}
		}

		&-info{

			@include breakPoint($breakPointCompact){
				padding: 0 15px;
			}

			ul{}
			li{

				&:before{
					background-color: $colorBlue;
					top: 10px;

					@include breakPoint($breakPointCompact){
						top: 4px;
					}

					.member__item_action &{
						background-color: $colorRed;
					}
				}

				&.note{
					margin-top: 25px;
					&:before{
						content: '*';
						color: $colorBlue;
						background: none;
						line-height: 0;
						top: 20px;
						font-size: 29px;
						font-weight: 600;

						@include breakPoint($breakPointCompact){
							font-size: 14px;
							top: 11px;
						}

						.member__item_action &{
							color: $colorRed;
						}
					}
				}
			}

		}

		&-timer{
			height: 100px;

			@include breakPoint($breakPointCompact){
				height: 70px;
			}

			&-watch{
				position: absolute;
				bottom: 22px;
				left: 22px;
				right: 22px;
			}

		}

		&-action{
			position: absolute;
			display: none;
			top: 100%;
			left: -1px;
			right: -1px;
			border: 1px solid $colorBorder;
			border-top: none;
			@include border-radius(0 0 4px 4px);

			padding: 11px 11px 10px;
			margin-top: -4px;

			@include breakPoint($breakPointCompact){
				padding: 5px;
			}

			&-inner{
				display: table;width: 100%;
			}

			&-btn{
				display: table-cell;
				vertical-align: middle;
				padding: 11px;

				@include breakPoint(0, $breakPointCompact - 1px){
					.slick-slide &{
						display: block;
					}
				}

				@include breakPoint($breakPointCompact){
					padding: 5px;
				}

				.btn{
					padding-right: 5px;
					padding-left: 5px;
					display: block;

				}
			}

			.bg-light &{
				background: $colorLight;
			}
		}
	}
}


.timer{
	font-size: 18px;
	display: table;
	width: 100%;
	color: $colorRed;
	margin: 10px 0;

	@include breakPoint($breakPointCompact){
		font-size: 12px;
	}


	&__title{
		text-align: right;
		padding-right: 25px;

		@include breakPoint($breakPointCompact){
			padding-right: 20px;
		}
	}

	&__item{
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		white-space: nowrap;
		width: 19%;
		color: $colorGrey;

		&.is-active{
			color: currentColor;
		}

		&_title{
			text-align: right;
			border-right: 1px solid currentColor;
			width: 24%;
			color: currentColor;
		}
	}
	&__value{
		font-size: 36px;
		font-weight: 500;
		line-height: 1;

		@include breakPoint($breakPointCompact){
			font-size: 24px;
		}
	}
	&__name{}
}