$popupZIndex: 100;
$popupOverlayColorM: #006db8;
$popupOverlayColorD: rgba(0, 70, 120, 0.5);

.popup {

	display: none;

	&.is-open {
		display: block !important;
	}

	&__overlay {
		background-color: $popupOverlayColorM;
		display: block;
		height: 100%;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: $popupZIndex;

		@include breakPoint($breakPointCompact){
			background-color: $popupOverlayColorD;
		}
	}

	&__inner {
		height: 100%;
		left: 0;
		overflow: auto;
		position: fixed;
		text-align: center;
		top: 0;
		width: 100%;
		z-index: $popupZIndex;
		display: block;
		background: transparent;
		overflow-y: scroll;
		font-size: 0;
		min-width: $minWidth;

		&:before {
			content: '';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}

	}

	&__layout {
		background-color: white;
		display: inline-block;
		margin: 0 auto;
		padding: 0;
		position: relative;
		vertical-align: middle;
		width: 100%;
		max-width: 600px;
		min-height: 100px;
		text-align: left;
		font-size: 24px;
		@include box-sizing();
		@include border-radius(4px);


		@include breakPoint($breakPointCompact){
			max-width: 476px;
			font-size: 14px;
		}
	}

	&__close {
		width: 44px;
		height: 44px;
		position: absolute;
		cursor: pointer;
		top: 30px;
		right: 40px;

		@include breakPoint($breakPointCompact){
			width: 26px;
			height: 26px;
			top: -3px;
			right: -55px;
		}

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			background: $popupOverlayColorM;
			height: 6px;
			width: 44px;
			margin-top: -3px;
			margin-left: -22px;
			border-radius: 10px;

			@include breakPoint($breakPointCompact){
				background: #fff;
				height: 4px;
				width: 26px;
				margin-top: -2px;
				margin-left: -13px;
			}

		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}

		&:hover {

		}
	}


	&__content{
		padding: 30px 43px 43px;

		@include breakPoint($breakPointCompact){
			padding: 50px;
		}
	}


	&__title{
		font-size: 36px;
		font-weight: 500;
		padding-right: 70px;
		margin-bottom: 35px;

		@include breakPoint($breakPointCompact){
			font-size: 40px;
			margin-bottom: 15px;
			padding-right: 0;
		}
	}

	&__text{
		color: $colorGrey;
		margin-bottom: 55px;

		@include breakPoint($breakPointCompact){
			margin-bottom: 25px;
		}
	}

	&__message{
		color: $colorGrey;
		text-align: center;

		height: 174px;
		display: table-cell;
		vertical-align: middle;
		padding: 0 0 55px;

		@include breakPoint($breakPointCompact){
			height: 92px;
			padding: 0 0 25px;
		}

	}


	&__contact{
		margin-top: 55px;
		margin-bottom: -30px;

		@include breakPoint($breakPointCompact){
			margin-top: 25px;
			margin-bottom: -15px;
		}

		&-title{
			margin-bottom: 20px;
			display: block;

			@include breakPoint($breakPointCompact){
				margin-bottom: 10px;
			}
		}
		&-phone{
			font-size: 30px;
			font-weight: 500;
			color: $colorRed;
			position: relative;
			margin-bottom: 30px;
			display: block;

			@include breakPoint($breakPointCompact){
				font-size: 18px;
				margin-bottom: 15px;
			}


			&:after{
				content: '';
				width: 50px;
				height: 50px;
				@include backgroundSprite(76, 71);
				position: absolute;
				top: -4px;
				right: 0;

				@include breakPoint($breakPointCompact){
					width: 28px;
					height: 28px;
					top: -4px;
					@include backgroundSprite(134, 85);
				}

				@include breakPoint($breakPointWide){
					top: 2px;
				}
			}
		}
	}

	&__btn{
		.btn{
			display: block;
		}
	}


	&__address{
		position: relative;


		&-text{
			padding-left: 70px;
			font-size: 24px;
			line-height: 1.8;
			padding-bottom: 30px;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
				padding-left: 50px;
				line-height: 1.5;
			}

			@include breakPoint($breakPointWide){
				line-height: 1.83;
			}


			&:before{
				content: '';
				width: 50px;
				height: 50px;
				@include backgroundSprite(76, 5);
				position: absolute;
				top: -5px;
				left: 0px;

				@include breakPoint($breakPointCompact){
					width: 27px;
					height: 34px;
					top: -10px;
					@include backgroundSprite(133, 21);
				}

				@include breakPoint($breakPointWide){
					top: -5px;
				}
			}
		}

		&-map{
			background: #dfdfdf no-repeat 50% 50%;
			background-size: cover;
			padding: 530px 40px 40px;
			margin: 0 -43px -43px;
			@include border-radius(0 0 4px 4px);

			@include breakPoint($breakPointCompact){
				margin: 0 -50px -50px;
				padding-top: 300px;
			}
		}
	}

	&.is-loading{

		.popup__layout{
			background-image: url("../img/loader.svg");
			background-position: 50% 50%;
			background-repeat: no-repeat;
		}
	}
}


.popup_content{

	.popup{

		&__layout{
			max-width: 100%;
			min-height: 300px;
			overflow: hidden;

			@include breakPoint($breakPointCompact){
				max-width: 756px;
				margin: 50px 0;
				overflow: visible;
			}
		}

		&__close{
			z-index: 1;
			width: 66px;
			height: 66px;
			@include border-radius(2px);
			background: #fff;

			@include breakPoint($breakPointCompact){
				width: 42px;
				height: 42px;
				top: 50px;
				right: 50%;
				margin-right: -440px;
				position: fixed;

			}

			&:before,
			&:after {
				background: $colorBorder;
			}
		}


		&__content{
			padding: 0;


			.section{
				padding-left: 42px;
				padding-right: 42px;

				&:first-child{
					padding: 0;
					@include border-radius(4px 4px 0 0);
				}

				@include breakPoint($breakPointCompact){
					padding-left: 30px;
					padding-right: 30px;
				}
			}
		}
	}



	.on-pp{

		&_top{

			@include breakPoint($breakPointCompact){
				position: absolute;
				right: 0;
				margin-top: -15px;
			}
		}

		&_bottom{

			@include breakPoint($breakPointCompact){
				position: relative;
				margin-top: 5px;

				.btn{
					position: absolute;
					right: 0;
					margin-top: -44px;
				}
			}
		}

	}
}


