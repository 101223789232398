.is-filter-open{
	overflow: hidden;
}

.filter{

	@include user-select();


	&__mobile{

		&-title{
			font-size: 24px;
			color: $colorGrey;
			margin-bottom: 20px;
		}

		&-list{
			font-size: 0;
			white-space: nowrap;
		}

		&-item{
			font-size: 24px;
			@include border-radius(8px);
			line-height: 66px;
			height: 66px;
			@include box-sizing();
			border: 1px solid $colorBorder;
			padding: 0 60px 0 28px;
			position: relative;
			display: inline-block;
			vertical-align: middle;


			&_field{

				width: calc(100% - 88px);
				margin-right: 22px;
				background-color: #fff;

				&-count{
					color: $colorGrey;

					&.is-active{
						color: $colorBlue;
					}
				}

				&:before,
				&:after{
					content: '';
					width: 4px;
					height: 18px;
					background: #b7b7b7;
					position: absolute;
					top: 50%;
					margin-top: -9px;
					margin-right: -2px;
					right: 30px;
					@include transition();
					@include border-radius(10px);

					@include breakPoint($breakPointCompact){
						display: none;
					}
				}

				&:before{
					@include transform(rotate(-135deg));
					margin-right: -5px;
				}

				&:after{
					@include transform(rotate(135deg));
					margin-right: 5px;
				}

			}
			&_filter{
				width: 66px;
				height: 66px;
				@include box-sizing();
				padding: 0;
				background: #fff url("../img/ico/ico_filter.svg") no-repeat 50% 50%;
				background-size: cover;
			}
		}
	}

	&__title{

		display: none;
		padding: 25px 44px;
		font-size: 36px;
		border-bottom: 1px solid $colorBorder;

		@include breakPoint(0, $breakPointCompact){
			display: none;

			&.is-open{
				display: block;
			}

		}

		&-text{
		}

		&-close{
			width: 44px;
			height: 44px;
			position: absolute;
			cursor: pointer;
			top: 25px;
			right: 35px;

			&:before,
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				background: $colorBorder;
				height: 6px;
				width: 44px;
				margin-top: -3px;
				margin-left: -22px;
				border-radius: 10px;
			}
			&:before {
				transform: rotate(45deg);
			}
			&:after {
				transform: rotate(-45deg);
			}
		}
	}

	&__list{

		@include breakPoint(0, $breakPointCompact){

			display: none;
			position: fixed;
			overflow: auto;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #fff;
			z-index: 100;

			&-inner{
				min-height: calc(100% - 130px);
			}

			.is-filter-open &{
				display: block;
			}

		}
	}

	&__group{

		&_left{

			@include breakPoint($breakPointCompact){
				display: table-cell;
				padding-right: 90px;
			}
		}

		&_right{

			@include breakPoint($breakPointCompact){
				display: table-cell;
				white-space: nowrap;
				width: 10%;

				.filter__field{
					white-space: normal;
					margin-left: 6px;
				}
			}
		}
	}
	&__field{

		@include breakPoint($breakPointCompact){
			display: inline-block;
			vertical-align: top;
		}

		&-title{
			font-size: 24px;
			color: $colorGrey;
			margin-bottom: 20px;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
				margin-bottom: 10px;
			}
		}

		@include breakPoint(0, $breakPointCompact){
			display: none;
			padding: 20px 44px;
			border-top: 1px solid $colorBorder;
			margin-top: -1px;

			&.is-open{
				display: block;
			}

		}

	}

	&__tags{

		&-item{
			position: relative;
			display: inline-block;
			vertical-align: middle;
			margin: 0 16px 20px 0;
			font-size: 20px;
			font-weight: 500;

			@include breakPoint($breakPointCompact){
				margin: 0 6px 10px 0;
				font-size: 12px;
			}

			input{
				position: absolute;

				&:checked{
					& ~ span{
						background: $colorBlue;
						border-color: $colorBlue;
						color: #fff;
					}
				}
			}

			span{
				display: block;
				height: 42px;
				padding: 0 20px;
				@include border-radius(4px);
				border: 1px solid $colorBorder;
				line-height: 42px;
				cursor: pointer;

				@include breakPoint($breakPointCompact){
					height: 19px;
					padding: 0 10px;
					line-height: 19px;
				}
			}

			&:hover{
				span{
					border-color: $colorBlue;
				}
			}

			&_all{
				border: none;
				color: $colorBlue;

				@include breakPoint(0, $breakPointCompact){
					display: block;
					margin-top: 20px;
				}

				@include breakPoint($breakPointCompact){
					height: 19px;
					padding: 0 10px;
					line-height: 19px;
				}
			}
		}
	}

	&__radio{

		@include breakPoint($breakPointCompact){
			padding-top: 14px;
		}

		&-item{
			position: relative;
			display: inline-block;
			vertical-align: middle;
			margin: 0 35px 20px 0;
			font-size: 20px;
			font-weight: 500;

			@include breakPoint($breakPointCompact){
				margin: 0 30px 20px 0;
				font-size: 14px;
				font-weight: 400;
			}

			input{
				position: absolute;

				&:checked{
					& ~ span{
						&:after{
							content: '';
							display: block;
							position: absolute;
							top: 7px;
							left: 7px;
							width: 20px;
							height: 20px;
							@include border-radius(50%);
							background: $colorBlue;

							@include breakPoint($breakPointCompact){
								width: 14px;
								height: 14px;
								top: 5px;
								left: 5px;
							}
						}

						&:before{
							border: 2px solid $colorBlue;
						}
					}
				}
			}

			span{
				cursor: pointer;
				position: relative;
				padding: 5px 0 5px 45px;

				@include breakPoint($breakPointCompact){
					padding: 4px 0 4px 35px;
				}


				&:before{
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 34px;
					height: 34px;
					@include border-radius(50%);
					border: 1px solid $colorBorder;
					background: #fff;
					@include box-sizing();

					@include breakPoint($breakPointCompact){
						width: 24px;
						height: 24px;
					}
				}
			}

			&:hover{
				span{
					border-color: $colorBlue;
				}
			}

		}
	}


	&__select{
		position: relative;
		background: #fff;

		@include breakPoint($breakPointCompact){
			width: 210px;
		}

		&-arrow{
			position: absolute;
			top: 50%;
			right: 20px;
			width: 30px;
			height: 30px;
			margin-top: -15px;
			margin-right: -15px;

			&:before,
			&:after{
				content: '';
				width: 2px;
				height: 8px;
				background: $colorBorder;
				position: absolute;
				top: 50%;
				margin-top: -4px;
				margin-right: -1px;
				right: 15px;
				@include transition();
				@include border-radius(10px);
			}

			&:before{
				@include transform(rotate(-135deg));
				margin-right: -2px;
			}

			&:after{
				@include transform(rotate(135deg));
				margin-right: 2px;
			}

		}

		&-count{
			color: $colorGrey;

			.is-selected &{
				color: $colorBlue;
			}
		}

		&-value{
			display: none;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
				@include border-radius(4px);
				line-height: 42px;
				height: 42px;
				@include box-sizing();
				border: 1px solid $colorBorder;
				padding: 0 40px 0 20px;
				position: relative;
				display: block;
				cursor: pointer;
				color: $colorGrey;

				&:hover{
					color: $colorBlack;


					.filter__select-arrow{

						&:before,
						&:after{
							background: $colorBlue;
						}
					}
				}
			}

		}

		&.is-selected{


			.filter__select-value{
				@include breakPoint($breakPointCompact){
					border-color: $colorBlue;
					color: $colorBlack;
					@include box-shadow(inset 0 0 0 1px $colorBlue)
				}
			}


			&:not(.is-open){
				.filter__select-arrow{


					&:before,
					&:after{
						height: 14px;
						margin-top: -7px;
						margin-right: 1px;
					}
				}
			}
		}

		&.is-open{
			.filter__select-list{
				display: block;
			}

			.filter__select-value{
				border-color: $colorBorder;
				@include border-radius(4px 4px 0 0);
				color: $colorBlack;
				@include box-shadow(none);

				.filter__select-arrow{
					&:before{
						@include transform(rotate(-45deg));
					}

					&:after{
						@include transform(rotate(45deg));
					}
				}
			}

			.filter__select-list{
				display: block;
			}
		}


		&-list{
			font-size: 24px;

			@include breakPoint($breakPointCompact){
				display: none;
				font-size: 14px;
				position: absolute;
				top: 100%;
				border: 1px solid $colorBorder;
				@include border-radius(0 0 4px 4px);
				left: 0;
				right: 0;
				background: #fff;
				z-index: 1;
				margin-top: -1px;
				max-height: 165px;
				overflow: auto;
			}
		}

		&-item{
			display: block;
			position: relative;


			input{
				position: absolute;

				&:checked{
					& ~ span{
						color: $colorBlue;

						@include breakPoint($breakPointCompact){
							background-color: $colorLight;
						}

						&:before{
							content: '';
							display: block;
							width: 21px;
							height: 15px;
							background: url("../img/ico/check-arrow.svg") no-repeat 50% 50%;
							background-size: contain;
							position: absolute;
							top: 33px;
							left: -20px;

							@include breakPoint($breakPointCompact){
								width: 10px;
								height: 7px;
								left: auto;
								top: 18px;
								right: 15px;
							}

						}
					}
				}
			}

			span{
				position: relative;
				display: block;
				border-bottom: 1px solid $colorBorder;
				padding: 28px 20px 30px;

				@include breakPoint($breakPointCompact){
					padding: 13px 20px 12px;
					border: 0;

					&:hover{
						background-color: $colorLight;
					}
				}
			}
		}
	}

	&__submit{
		left: 0;
		right: 0;
		bottom: 0;
		padding: 11px;
		font-size: 0;

		&-btn{
			display: inline-block;
			vertical-align: middle;
			padding: 11px;
			width: 50%;
			@include box-sizing();
		}

		.btn{
			width: 100%;
		}
	}
}