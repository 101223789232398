
$fieldHeight: 66px;
$fieldHeightD: 42px;
$borderRadius: 4px;
$borderRadiusD: 4px;


.placeholder{
	position: absolute;
	color: $colorGrey;
	top: 0;
	right: 100%;
	left: 0;
	text-align: right;
	line-height: $fieldHeight;
	cursor: default;
	pointer-events: none;

	white-space: nowrap;
	@include box-sizing();
	@include transition();
	padding: 0 42px;

	@include breakPoint($breakPointCompact){
		line-height: $fieldHeightD;
		padding: 0 21px;
	}

	.error &{
		color: $colorRed;
	}

	.f-filled &{
		right: 0;
	}

}

.form{

	font-size: 24px;

	@include breakPoint($breakPointCompact){
		font-size: 14px;
	}

	&__field{
		position: relative;
		margin-bottom: 36px;

		@include breakPoint($breakPointCompact){
			margin-bottom: 18px;
		}

		&:last-child{
			margin-bottom: 0;
		}

		&.error{

			input{
				&[type="email"],
				&[type="password"],
				&[type="tel"],
				&[type="text"]{
					border-color: $colorRed!important;
				}
			}
		}

	}

	&__message{
		display: none;
		top: 8px;
		position: relative;

		.f-message &{
			display: block;
		}

		.error &{
			color: $colorRed;
		}
	}


	&__label{

		color: #f00;
		padding-bottom: 10px;


	}


	&__input{
		position: relative;

		input{

			&[type="email"],
			&[type="password"],
			&[type="tel"],
			&[type="text"]{
				@include box-sizing();
				width: 100%;
				display: block;
				line-height: ($fieldHeight - 2px);
				height: $fieldHeight;
				border: 1px solid $colorBorder;
				@include border-radius($borderRadius);
				background: #fff;
				//@include transition('border');
				padding: 0 42px;

				@include breakPoint($breakPointCompact){
					padding: 0 21px;
					line-height: ($fieldHeightD - 2px);
					height: $fieldHeightD;
					@include border-radius($borderRadiusD);
				}


				&:focus{
					border-color: $colorBlue;

					& ~ .placeholder{
						right: 0;
						//font-size: 12px;
					}
				}

				.error &{
					border-color: $colorRed;
				}

				&:hover{
					//border-color: $colorBlue;
				}


				&:disabled{
					background: $colorLight!important;
					border-color: $colorLight!important;
					color: $colorGrey!important;
				}
			}


			&[type="checkbox"]{
				position: absolute;
				opacity: 0;

				& ~ .label{
					position: relative;
					padding-left: 65px;
					display: block;

					@include breakPoint($breakPointCompact){
						padding-left: 35px;
						padding-top: 6px;
					}

					&:before{
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 40px;
						height: 40px;
						border: 2px solid $colorBlue;
						@include border-radius($borderRadius);
						background: #fff;

						@include breakPoint($breakPointCompact){
							width: 21px;
							height: 21px;
							@include border-radius($borderRadiusD);
						}
					}
				}

				&:checked{
					& ~ .label{
						&:before{
							background: #fff url("../img/ico/checkbox.svg") no-repeat 50% 50%;
							background-size: cover;
						}
					}

				}
			}

			&[type="submit"]{
				@include box-sizing();
				background: $colorBlue;
				color: #fff;
				cursor: pointer;
				width: 100%;
				display: block;

				&:not(.btn){
					line-height: $fieldHeight;
					@include border-radius($borderRadius);

					@include breakPoint($breakPointCompact){
						line-height: $fieldHeightD;
						@include border-radius($borderRadiusD);
					}
				}

				&:hover{
					background-color: $colorBlueHover;
					border-color: $colorBlueHover;
				}
			}
		}

		select{
			@include box-sizing();
			width: 100%;
			display: block;
			line-height: ($fieldHeight - 2px);
			height: $fieldHeight;
			border: 1px solid $colorBorder;
			@include border-radius($borderRadius);
			background: #fff;
			@include transition('border');
			padding: 0 18px 0 18px;

			@include breakPoint($breakPointCompact){
				line-height: ($fieldHeightD - 2px);
				height: $fieldHeightD;
				padding: 0 10px 0 10px;
				@include border-radius($borderRadiusD);
			}


			&:focus{
				border-color: $colorBlue;
			}

			.error &{
				border-color: $colorRed;
			}

			&:hover{
				border-color: $colorBlue;
			}

			&:disabled{
				background: $colorLight!important;
				border-color: $colorLight!important;
				color: $colorGrey!important;
			}
		}

		textarea{
			@include box-sizing();
			width: 100%;
			display: block;
			border: 1px solid $colorGrey;
			background: #fff;
			@include border-radius($borderRadius);
			padding: 15px 18px;
			@include transition('border');
			height: 280px;

			@include breakPoint($breakPointCompact){
				height: 140px;
			}

			&:focus{
				border-color: $colorBlue;

				& ~ .placeholder{
					right: 0;
					//font-size: 12px;
				}
			}

			.error &{
				border-color: $colorRed;
			}

			&:hover{
				border-color: $colorBlue;
			}

			&:disabled{
				background: $colorLight!important;
				border-color: $colorLight!important;
				color: $colorGrey!important;
			}
		}
	}
}


