


.announce{
	position: relative;


	&_content{
		margin: 0 -45px;

		@include breakPoint($breakPointCompact){
			margin: 0 -30px;
		}

		.popup_content &{

			margin: 0;
		}
	}

	&__list{
		font-size: 0;
		margin: -22px;

		@include breakPoint($breakPointCompact){
			margin: -10px;
		}

	}
	&__item{
		display: inline-block;
		max-width: 550px;
		padding: 22px;
		font-size: 24px;
		vertical-align: top;
		@include box-sizing();

		@include breakPoint($breakPointCompact){
		}


		.popup_content &,
		#content &{
			width: 100%;
			max-width: 100%;
			padding: 0;
		}

		&_gouble{
			.announce__item-inner{
				&:first-child{
					margin-bottom: 44px;

					@include breakPoint($breakPointCompact){
						margin-bottom: 20px;
					}
				}
			}

			.announce__item-title{

				@include breakPoint($breakPointCompact){
					-webkit-line-clamp: 1;
					white-space: nowrap;
					display: block;
				}
			}

			.announce__item-description {

				@include breakPoint($breakPointCompact){
					height: 91px;
				}
			}
		}


		&_noimg{
			.announce__item-description {

				@include breakPoint($breakPointCompact){
					height: 311px;
				}
			}
		}

		@include breakPoint($breakPointCompact){
			padding: 10px;
			font-size: 14px;
			width: 379px;
		}

		&-inner{
			@include border-radius(4px);
			background: #fff;
			position: relative;
			@include box-sizing();
			overflow: hidden;

			@include box-shadow(0 0 0 rgba(0,0,0,0));
			@include transition();

			&:hover{
				@include box-shadow(0 0 20px rgba(0,0,0,0.3));
			}

			@include breakPoint($breakPointCompact){

			}

			&:hover{

			}
		}
		&-image{
			//width: 100%;
			font-size: 0;
			@include border-radius(4px 4px 0 0);

			width: 100%;
			height: 300px;
			overflow: hidden;

			margin: 0;
			position: relative;

			@include breakPoint($breakPointCompact){
				height: 196px;
			}


			.popup_content &,
			#content &{
				height: auto;
			}


			img{
				width: 100%;
			}

			.announce__item-label{
				position: absolute;
				margin: 0;
				top: 22px;
				left: 22px;


				@include breakPoint($breakPointCompact){
					top: 16px;
					left: 25px;
				}

			}
		}

		&-info{
			padding: 0 22px;
			margin: 22px 0;

			@include breakPoint($breakPointCompact){
				padding: 0 20px;
				margin: 20px 0;
			}
		}

		&-description{
			height: 185px;

			@include breakPoint($breakPointCompact){
				height: 115px;
			}
		}

		&-footer{
			margin: 22px 0;
			overflow: hidden;

			@include breakPoint($breakPointCompact){
				margin: 20px 0;
			}

			.announce__item-more{
				float: right;

				@include breakPoint($breakPointCompact){
					float: left;
				}

				.btn{

					padding: 0;
					width: 62px;
					color: transparent;
					height: 62px;
					font-size: 0;
					background: #fff url("../img/ico/arrow-next.svg") no-repeat 0 0;
					background-size: cover;
					border: 2px solid $colorRed;

					&:hover{
						//background-color: $colorRed;
					}

					@include breakPoint($breakPointCompact){
						//border-width: 1px;
						background: #fff;
						width: auto;
						font-size: 14px;
						font-weight: 500;
						color: $colorBlack;
						padding: 0 20px;
						height: 42px;
						line-height: 38px;

						&:hover{
							background: $colorRedHover;
							color: #fff;
						}
					}

				}
			}
		}

		&-title{
			font-size: 36px;
			font-weight: 500;
			margin-bottom: 15px;
			width: 100%;

			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
			}

			a{
				color: inherit;
			}
		}

		&-text{
			color: $colorGrey;
			max-height: 85px;
			width: 100%;

			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&-date{
			font-size: 24px;
			color: $colorGrey;
			margin: 5px 0;

			@include breakPoint($breakPointCompact){
				font-size: 12px;
				margin: 5px 0;
			}
		}

		&-action{
			color: $colorRed;
			font-weight: 300;
			float: left;

			@include breakPoint($breakPointCompact){
				float: right;
				text-align: right;
			}

			&-title{

				@include breakPoint($breakPointCompact){
					margin-bottom: 5px;
				}
			}

			&-value{
				font-size: 36px;
				font-weight: 500;

				@include breakPoint($breakPointCompact){
					font-size: 20px;
				}
			}
		}

		&-more{

			.btn{
				background-color: #fff;
				border-color: $colorRed;
				border-width: 2px;
				color: $colorBlack;
			}
		}


		&-label{
			margin-top: 22px;

			@include breakPoint($breakPointCompact){
				margin-top: 20px;
			}

			.label{
				display: inline-block;
				vertical-align: middle;
				color: #fff;
				background-color: $colorGrey;
				font-size: 18px;
				line-height: 1;
				padding: 13px 25px;
				@include border-radius(4px);
				//cursor: default;
				margin: 0 20px 20px 0;

				@include breakPoint($breakPointCompact){
					font-size: 12px;
					padding: 4px 10px 3px;
					margin: 0 10px 10px 0;
					@include border-radius(2px);
				}

				&_red{
					background-color: $colorRed;
				}

				&_blue{
					background-color: $colorBlue;
				}
			}
		}

	}


	&_news{
		.announce{
			&__item{

				@include breakPoint($breakPointCompact){
					width: 650px !important;
				}

				&-image{
					@include breakPoint($breakPointCompact){
						height: 364px;

						img{
							width: auto;
							height: 100%;

						}
					}
				}

				&-description{
					@include breakPoint($breakPointCompact){
						height: 100px;
					}
				}

				&-title{
					@include breakPoint($breakPointCompact){
						font-size: 30px;
						display: block;
						white-space: nowrap;
					}
				}

				&-text{
					@include breakPoint($breakPointCompact){
						font-size: 14px;
						max-height: 35px;
						-webkit-line-clamp: 2;
					}
				}
			}
		}
	}
}


.timer{
	font-size: 18px;
	display: table;
	width: 100%;
	color: $colorRed;
	margin: 10px 0;

	@include breakPoint($breakPointCompact){
		font-size: 12px;
	}


	&__title{
		text-align: right;
		padding-right: 25px;

		@include breakPoint($breakPointCompact){
			padding-right: 20px;
		}
	}

	&__item{
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		white-space: nowrap;
		width: 19%;
		color: $colorGrey;

		&.is-active{
			color: currentColor;
		}

		&_title{
			text-align: right;
			border-right: 1px solid currentColor;
			width: 24%;
			color: currentColor;
		}
	}
	&__value{
		font-size: 36px;
		font-weight: 500;
		line-height: 1;

		@include breakPoint($breakPointCompact){
			font-size: 24px;
		}
	}
	&__name{}
}