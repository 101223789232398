
.appointment{

	text-align: center;

	@include breakPoint($breakPointCompact){
		text-align: left;
	}

	&_content{
		text-align: left;

		.appointment__form{

			@include breakPoint($breakPointCompact){
				margin-right: -15px;
				margin-left: -15px;
			}

			.form{

				&__field{

					@include breakPoint($breakPointCompact){
						width: 50%;
						float: left;
						padding-right: 15px;
						padding-left: 15px;
						@include box-sizing();
						display: block;
						margin-left: 0;
					}

					&_w{

						@include breakPoint($breakPointCompact){
							width: 100%;
						}
					}

					&_fileasset{
						color: $colorGrey;

						@include breakPoint($breakPointCompact){
							padding-top: 5px;
						}
					}

					&_filelist{
						overflow: hidden;
					}
				}

				&__input{
					&_file{

						input{
							position: absolute;
							opacity: 0;

							&:disabled{

								& ~ .btn{
									cursor: default;
									background-color: $colorGrey!important;
									border-color: $colorGrey!important;
								}
							}
						}
						.btn{
							display: block;
						}
					}
				}
			}
		}
	}

	&__aside{

		&_l{
			@include breakPoint($breakPointCompact){
				float: left;
				width: 550px;
			}
		}

		&_r{
			@include breakPoint($breakPointCompact){
				float: right;
			}
			@include breakPoint($breakPointWide){
				width: 800px;
			}
		}

		.form{
			@include breakPoint($breakPointCompact){
				margin-top: 20px;
			}
		}
	}
	&__title{
		font-size: 48px;
		font-weight: 600;
		margin-bottom: 32px;

		@include breakPoint($breakPointCompact){
			font-size: 40px;
			max-width: 550px;
		}
	}
	&__text{
		color: $colorGrey;
		font-size: 24px;
		margin-bottom: 50px;

		@include breakPoint($breakPointCompact){
			font-size: 14px;
			max-width: 550px;
			margin-bottom: 0;
		}
	}
	&__form{
		.form{
			&__field{

				input{
					&[type="email"],
					&[type="password"],
					&[type="tel"],
					&[type="text"]{
						border-color: #fff;
					}

				}
				textarea{
					border-color: #fff;
				}

				@include breakPoint($breakPointCompact){
					margin-bottom: 30px;
					width: 360px;

					&:last-child{
						margin-bottom: 0;
					}
				}
				@include breakPoint($breakPointWide){
					display: inline-block;
					vertical-align: top;
					margin-left: 15px;
				}

			}
		}
	}

}