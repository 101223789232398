$breakPointCompact: 1260px;
$breakPointWide: 1660px;


$colorLight: #f2f2f2;
$colorGrey: #89898b;
$colorRed: #fe3265;
$colorRedHover: #f34977;
$colorBlue: #006db8;
$colorBlueHover: #1c82ca;
$colorBlueLight: #63a9dc;
$colorBlack: #2c2f34;

$colorOrange: #ff6600;


$colorBorder: #b7b7b7;

$minWidth: 640px;

$minInner: 550px;
$contentInner: 756px; //1118px;
$tabletInner: 1120px; //1118px;
$desktopInner: 1500px; //1498px;



$headerHeightMobile: 88px;
$headerHeightDesktop: 70px;


.c-blue{
	color: $colorBlue!important;
}


.c-red{
	color: $colorRed!important;
}
.c-black{
	color: $colorBlack!important;
}





.bg-light{
	background-color: $colorLight!important;
}
.bg-grey{
	background-color: $colorGrey!important;
}
.bg-dark{
	background-color: #e7e7e7!important;
}
.bg-red{
	background-color: $colorRed!important;
}

