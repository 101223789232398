
.section_cards{
	overflow: hidden;
}

.cards{

	&__list{
		margin: 0 -15px;
		font-size: 0;
		white-space: nowrap;

		@include breakPoint($breakPointCompact){
			margin: 15px -10px;
		}
	}

	&__item{
		width: 406px!important;
		@include box-sizing();
		padding: 15px;
		display: inline-block;
		white-space: normal;
		vertical-align: top;

		@include breakPoint($breakPointCompact){
			padding: 10px;
			width: 380px!important;
		}

		&-inner{
			border: 1px solid $colorBorder;
			@include border-radius(4px);
			padding: 22px 22px 10px;
		}
		&-image{
			width: 330px;
			height: 210px;
			font-size: 0;
			@include border-radius(10px);
			overflow: hidden;
			@include box-shadow(0 0 30px rgba(0,0,0,0.3));
			margin-bottom: 20px;

			@include breakPoint($breakPointCompact){
				width: 336px;
				height: 210px;
				margin-top: -10px;
				margin-left: -10px;
			}

			img{
				width: 100%;
				height: 100%;
			}

		}
		&-title{
			font-weight: 500;
			font-size: 36px;
			margin-bottom: 15px;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
			}
		}
		&-text{
			font-size: 24px;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
			}

			ul{

				li{
					margin-bottom: 15px;

					&:before{
						top: 10px;

						@include breakPoint($breakPointCompact){
							top: 5px;
						}
					}

				}

				.note{
					color: $colorRed;

					&:before{
						content: '*';
						font-weight: 600;
						font-size: 28px;
						background: none;
						top: 3px;
						left: -1px;

						@include breakPoint($breakPointCompact){
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}