

@mixin tableCell(){
	position: static;
	display: table-cell;
	vertical-align: middle;
	height: $headerHeightDesktop;
}

.header{

	height: $headerHeightMobile;

	@include breakPoint($breakPointCompact){
		height: $headerHeightDesktop;
	}

	&__inner{
		background: #fff;
		height:$headerHeightMobile;
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		padding-left: $headerHeightMobile;
		@include box-sizing();
		@include box-shadow(0 0 10px rgba(0,0,0,0.35));

		@include breakPoint($breakPointCompact){
			//padding-left: $headerHeightDesktop;
			height: auto;
			display: table;
			padding: 0;
			width: 100%;
		}
	}

	&__logo{
		width: 231px;
		height: 72px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -(72px/2);
		margin-left: -(231px/2);

		@include breakPoint($breakPointCompact){
			//width: 180px;
			//height: 56px;
			//margin-left: 0;
			//margin-top: -(56px/2);
			//left: 45px + $headerHeightDesktop;

			@include tableCell();
			margin: 0;
			width: auto;
			height: auto;
			padding: 0 50px 0 110px;
		}

		img{
			width: 100%;
			height: 100%;

			@include breakPoint($breakPointCompact){
				width: 180px;
				height: 56px;
				display: block;
			}
		}
	}

	&__address{
		display: none;


		@include breakPoint($breakPointCompact){
			//display: inline-block;
			//margin-left: 280px;
			@include tableCell();
			white-space: nowrap;
		}

		&-item{
			position: relative;
			display: inline-block;
			padding-left: 45px;
			vertical-align: middle;
			margin-right: 50px;
			white-space: normal;

			&:before{
				content: '';
				display: block;
				width: 26px;
				height: 25px;
				top: -2px;
				left: 5px;
				@include backgroundSprite(214, 70);
				position: absolute;
			}

			&:nth-of-type(1){

				&:before{
					@include backgroundSprite(213, 70);
				}
			}

			&:nth-of-type(2){

				&:before{
					@include backgroundSprite(293, 70);
				}
			}
		}

		&-title{
			font-weight: 500;
			margin-bottom: 5px;
			font-size: 16px;
		}

		&-info{
			font-size: 12px;
			color: $colorGrey;
		}

	}

	&__nav{
		display: none;
		white-space: nowrap;

		@include breakPoint($breakPointWide){
			//display: inline-block;
			//vertical-align: middle;
			font-size: 16px;
			font-weight: 600;

			@include tableCell();
		}

		&-item{
			display: inline-block;
			margin-left: 25px;

			&:first-child{
				margin-left: 0;
			}
		}
	}

	&__social{
		display: none;

		@include breakPoint($breakPointCompact){
			//display: inline-block;
			//vertical-align: middle;
			//padding: 25px 0;
			@include tableCell();
			padding: 0 40px;
			white-space: nowrap;
		}

		&-item{
			display: inline-block;
			vertical-align: middle;
			background: url("../img/ico/ico-soc.svg") no-repeat 0 0;
			background-size: 100% auto;
			width: 20px;
			height: 20px;
			margin-right: 25px;

			&:last-child{
				margin-right: 0;
			}

			&_vk{
				background-position: 0 0;
			}
			&_fb{
				background-position: 0 33.33333%;
			}
			&_yt{
				background-position: 0 66.66666%;
			}
			&_ig{
				background-position: 0 100%;
			}
		}
	}


	&__call{
		width: 50px;
		height: 50px;
		@include backgroundSprite(76, 124);
		position: absolute;
		top: 20px;
		right: 18px;

		@include breakPoint($breakPointCompact){
			display: none;
		}
	}
}