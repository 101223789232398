
.trainers{

	margin-top: 55px;

	@include breakPoint($breakPointCompact){
		margin-top: 55px;
		position: relative;
	}

	&__list{
		margin: -16px;
		font-size: 0;
		//white-space: nowrap;

		@include breakPoint($breakPointCompact){
			margin: -17.5px;
			white-space: normal;
		}

		@include breakPoint($breakPointWide){
			margin: -10px;
		}
	}

	&__item{
		//@include box-sizing();
		width: 100%;
		display: inline-block;
		white-space: normal;

		@include breakPoint(0, $breakPointCompact - 1px){


			.slick-slide &{
				width: 398px!important;
			}
		}

		@include breakPoint($breakPointCompact){
			@include box-sizing();
			width: 230px;
		}

		@include breakPoint($breakPointWide){
			width: 216px;
		}


		&-inner{
			background: #fff;
			@include border-radius(8px);
			position: relative;
			padding: 45px 30px;
			height: 465px;
			margin: 16px;
			@include box-sizing();

			@include breakPoint($breakPointCompact){
				height: 250px;
				padding: 20px;
				margin: 10px 17px;
				@include border-radius(4px);
			}

			@include breakPoint($breakPointWide){
				margin: 10px;
			}
		}


		&-image{
			width: 189px;
			height: 189px;
			margin: 0 0 30px;
			@include border-radius(50%);
			background: $colorLight no-repeat 50% 50%;
			background-size: cover;
			font-size: 0;

			@include breakPoint($breakPointCompact){
				width: 100px;
				height: 100px;
				margin-bottom: 13px;
			}

			&:before{
				content: '';
				display: inline-block;
				vertical-align: middle;
				height: 100%;
			}

			img{
				display: inline-block;
				vertical-align: middle;
				width: 100%;
				height: 100%;
			}
		}

		&-icons{
			position: absolute;
			top: 25px;
			right: 40px;

			@include breakPoint($breakPointCompact){
				top: 15px;
				right: 20px;
			}

			.icon{
				display: block;
				width: 60px;
				height: 60px;
				margin-bottom: 20px;

				@include breakPoint($breakPointCompact){
					width: 30px;
					height: 30px;
					margin-bottom: 10px;
				}


				img{
					width: 100%;
					height: 100%;
				}
			}
		}
		&-name{
			font-size: 36px;
			font-weight: 500;
			color: $colorBlue;
			height: 86px;
			margin-bottom: 15px;
			overflow: hidden;

			a{
				color: inherit;

				&:hover{
					color: $colorRed;
				}
			}

			@include breakPoint($breakPointCompact){
				font-size: 20px;
				height: 48px;
				margin-bottom: 10px;
			}
		}
		&-text{
			font-size: 24px;
			height: 56px;
			overflow: hidden;

			@include breakPoint($breakPointCompact){
				font-size: 12px;
				height: 28px;
			}
		}
	}
}

