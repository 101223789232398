
.section_specificity{
	overflow: hidden;

}


.specificity{

	@include breakPoint($breakPointCompact){
		margin-top: 55px;
		position: relative;
	}

	&__list{
		margin: -16px;
		font-size: 0;
		//white-space: nowrap;

		@include breakPoint($breakPointCompact){
			margin: -17.5px;
			white-space: normal;
		}

		@include breakPoint($breakPointWide){
			margin: -10px;
		}
	}

	&__item{
		//@include box-sizing();
		width: 100%;
		text-align: center;
		display: inline-block;
		white-space: normal;

		@include breakPoint(0, $breakPointCompact - 1px){


			.slick-slide &{
				width: 398px!important;
			}
		}

		@include breakPoint($breakPointCompact){
			@include box-sizing();
			width: 230px;
		}

		@include breakPoint($breakPointWide){
			width: 216px;
		}


		&-inner{
			background: #fff;
			@include border-radius(8px);
			position: relative;
			padding: 30px;
			height: 366px;
			margin: 16px;
			@include box-sizing();

			@include breakPoint($breakPointCompact){
				height: 196px;
				padding: 25px 10px 10px;
				margin: 10px 17px;
				@include border-radius(4px);
			}

			@include breakPoint($breakPointWide){
				margin: 10px;
			}
		}

		&-label{
			background: $colorGrey;
			position: absolute;
			min-width: 50px;
			height: 34px;
			line-height: 36px;
			color: #fff;
			font-size: 24px;
			font-weight: 500;
			text-align: center;
			@include border-radius(4px);
			top: 30px;
			right: 32px;
			@include user-select();

			@include breakPoint($breakPointCompact){
				font-size: 12px;
				height: 19px;
				line-height: 20px;
				min-width: 28px;
				top: 16px;
				right: 14px;
			}
		}
		&-image{
			width: 189px;
			height: 189px;
			margin: 0 auto 30px;
			@include border-radius(50%);
			background: $colorLight no-repeat 50% 50%;
			background-size: cover;
			font-size: 0;

			@include breakPoint($breakPointCompact){
				width: 100px;
				height: 100px;
				margin-bottom: 13px;
			}

			&:before{
				content: '';
				display: inline-block;
				vertical-align: middle;
				height: 100%;
			}

			img{
				display: inline-block;
				vertical-align: middle;
				max-width: 100%;
				max-height: 100%;
			}
		}
		&-text{
			height: 80px;
			overflow: hidden;
			line-height: 1.1;

			@include breakPoint($breakPointCompact){
				height: 42px;
			}

			&:before{
				content: '';
				display: inline-block;
				vertical-align: middle;
				height: 100%;
			}

			span{
				font-size: 36px;
				font-weight: 500;
				display: inline-block;
				vertical-align: middle;

				@include breakPoint($breakPointCompact){
					font-size: 18px;
				}
			}
		}
	}
}

