
.section_gallery{

	overflow: hidden;

	.tabs{


		@include breakPoint($breakPointCompact){
			margin-top: -60px;

			.inner{
				padding-left: 300px;
			}
		}
	}
}

.gallery{
	margin-top: 55px;

	.popup_content &,
	#content &{
		margin-top: 0;
	}

	#content &{
		@include breakPoint($breakPointCompact){
			background: $colorLight;
			padding-bottom: 45px;
		}
	}

	&__list{
		margin:0 -22px;
		font-size: 0;
		white-space: nowrap;


		@include breakPoint($breakPointCompact){
			margin:0 -10px;
		}



		.popup_content &,
		#content &{

			margin: 0 -45px;

			.slick-list{
				@include breakPoint(0, $breakPointCompact - 1px){
					overflow: hidden!important;
				}
			}

			@include breakPoint($breakPointCompact){
				margin:0 -43px;
			}
		}
	}

	&__item{
		padding: 22px;
		max-width: 550px;


		@include breakPoint($breakPointCompact){
			padding: 10px;
			@include box-sizing();
		}

		.popup_content &,
		#content &{
			padding: 0;
			max-width: 100%;

			@include breakPoint($breakPointCompact){
				padding: 0 13px;
			}
		}

		&-inner{
			@include border-radius(3px);
			overflow: hidden;

			img{
				max-width: 100%;
				max-height: 100%;
				margin: 0 auto;

				@include breakPoint($breakPointCompact){
					width: auto;
					height: 406px;
				}

				.popup_content &,
				#content &{
					width: 100%;
					height: auto;
				}
			}
		}

		&-link{
			display: block;
			position: relative;

			@include breakPoint($breakPointCompact){
				&:before{
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba($colorBlue, 0.5);
					opacity: 0;
					@include transition();
					z-index: 1;
					pointer-events: none;

					.popup &{
						display: none;
					}
				}

				&:after{
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: 44px;
					height: 44px;
					margin-top: -22px;
					margin-left: -22px;
					background: url("../img/ico/zoom.svg") no-repeat 50% 50%;
					background-size: contain;
					opacity: 0;
					@include transition();
					z-index: 1;
					pointer-events: none;

					.popup &{
						display: none;
					}
				}
			}

			&:hover{
				&:before,
				&:after{
					opacity: 1;
				}
			}
		}
	}

}