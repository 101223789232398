
$itemHeight: 110px;

.section_schedule{
	overflow: hidden;
}

.schedule{
	position: relative;

	&_section{
		margin-bottom: 50px;

		@include breakPoint($breakPointCompact){
			margin-bottom: 0;
		}
	}
	
	&__control{
		height: 70px;
		position: relative;
		margin-top: 60px;
		margin-bottom: -50px;

		.schedule_club &{

			@include breakPoint(0, $breakPointCompact - 1px){
				display: none;
			}

			@include breakPoint($breakPointCompact){

				position: static;
				height: 0;
				margin: 0;

				.slider-control{
					position: static;
					margin: 0;

					&__arrow{
						top: 50%;
						margin: -50px 0 0 0;
					}
				}
			}
		}

		@include breakPoint($breakPointCompact){
			height: 50px;
			position: relative;
			margin-top: 0px;
			margin-bottom: -40px;
		}

		.slider-control{
			display: block;

			@include breakPoint($breakPointCompact){
				&__arrow{
					width: 30px;
					height: 30px;
					top: 5px;

					&_prev{
						left: -50px;
					}
					&_next{
						right: -50px;
					}
				}
			}
		}



		.schedule_section &{
			@include breakPoint($breakPointCompact){
				display: none;
			}
		}
	}

	&__period{

		display: none;
		position: absolute;
		top: 56px;

		@include breakPoint($breakPointCompact){
			display: block;
			left: -55px;
			z-index: 1;
		}

		@include breakPoint($breakPointWide){
			left: -70px;
		}

		&-time{
			background-color: #e7e7e7;
			width: 42px;
			height: $itemHeight + 2px;
			@include border-radius(4px);
			margin-bottom: 20px;
			text-align: center;
			font-size: 0;

			@include breakPoint($breakPointWide){
				width: 56px;
			}

			&:before{
				content: '';
				display: inline-block;
				vertical-align: middle;
				height: 100%;
			}

			span{
				display: inline-block;
				vertical-align: middle;
				font-size: 14px;
			}

			&_2{

				@include breakPoint($breakPointCompact){
					height: (($itemHeight * 2) + 22px + 2px);
				}
			}

			&_3{

				@include breakPoint($breakPointCompact){
					height: (($itemHeight * 3) + 44px + 2px);
				}
			}
		}
	}

	&__list{
		margin: 0 -11px;

		@include breakPoint($breakPointCompact){
			margin: 0 -17px;
			overflow: hidden;
		}

		@include breakPoint($breakPointWide){
			margin: 0 -11px;
		}


		.schedule_section &{
			.slick-list{
				overflow: hidden!important;
			}
		}

		.schedule_section &{
			@include breakPoint($breakPointCompact){
				margin: 0 -8px;
			}
		}
	}

	&__column{

		.schedule_section &{
			@include breakPoint($breakPointCompact){
				font-size: 0;
				margin-bottom: 20px;
			}
		}
	}

	&__day{
		text-align: center;
		margin-bottom: 50px;
		font-size: 30px;

		@include breakPoint($breakPointCompact){
			margin-bottom: 14px;
			font-size: 18px;
		}

		.schedule_section &{
			@include breakPoint($breakPointCompact){
				text-align: left;
				font-size: 14px;
				padding: 0 8px;
				color: $colorGrey;
				margin-bottom: 5px;
			}
		}
	}

	&__item{
		padding: 11px;
		vertical-align: top;

		@include breakPoint($breakPointCompact){
			padding: 11px 17px;
		}

		@include breakPoint($breakPointWide){
			padding: 11px;
		}



		.schedule_section &{
			padding: 13px;

			@include breakPoint($breakPointCompact){
				display: inline-block;
				width: 176px;
				padding: 8px;
			}
		}

		&-inner{
			background: #fff;
			position: relative;
			@include border-radius(8px);
			padding: 80px 40px 40px 40px;
			overflow: hidden;
			@include box-sizing();
			height: 240px;
			@include box-shadow(0 0 0 1px #ccc);


			@include breakPoint($breakPointCompact){
				@include border-radius(4px);
				padding: 38px 20px 15px;
				height: $itemHeight;
			}

			.schedule_section &{
				padding: 40px;
				height: 160px;

				@include breakPoint($breakPointCompact){
					padding: 15px;
					height: 85px;
				}
			}
		}

		&-color{
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 7px;
			background: $colorGrey;

			.color{
				height: 100%;
				display: block;
				background: currentColor;

				&_2{
					height: 50%;
				}
				&_3{
					height: (100% / 3);
				}
			}

			@include breakPoint($breakPointCompact){
				width: 4px;
			}


			&_red{
				background-color: #f67b9c;
			}
			&_green{
				background-color: #a1cb83;
			}
			&_blue{
				background-color: #72bedb;
			}
			&_violet{
				background-color: #ce9bef;
			}
			&_yellow{
				background-color: #e6e594	;
			}
		}

		&-time{
			font-size: 24px;
			position: absolute;
			top: 40px;
			left: 40px;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
				top: 15px;
				left: 20px;
			}

			.schedule_section &{
				position: static;
				font-size: 36px;
				font-weight: 500;
				margin-bottom: 10px;

				@include breakPoint($breakPointCompact){
					font-size: 20px;
					margin-bottom: 15px;
				}
			}
		}

		&-place{
			font-size: 24px;
			color: $colorGrey;
			position: absolute;
			top: 40px;
			right: 40px;

			@include breakPoint($breakPointCompact){
				font-size: 12px;
				top: 15px;
				right: 20px;
			}
		}

		&-title{
			font-size: 36px;
			font-weight: 500;
			height: 85px;
			overflow: hidden;
			margin-bottom: 15px;

			@include breakPoint($breakPointCompact){
				font-size: 18px;
				height: 42px;
				margin-bottom: 5px;
			}
		}

		&-trainer{
			font-size: 24px;
			color: $colorGrey;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			@include breakPoint($breakPointCompact){
				font-size: 12px;
			}
		}


		&-cost{

			display: none;
			background: $colorRed;
			position: absolute;
			height: 34px;
			line-height: 36px;
			color: #fff;
			font-size: 24px;
			font-weight: 500;
			text-align: center;
			@include border-radius(4px);
			bottom: 30px;
			right: 32px;
			@include user-select();

			@include breakPoint($breakPointCompact){
				display: block;
				font-size: 12px;
				height: 19px;
				line-height: 20px;
				min-width: 28px;
				bottom: 8px;
				right: 22px;
			}

			& ~ .schedule__item-trainer{
				padding-right: 50px;

				@include breakPoint($breakPointCompact){
					padding-right: 35px;
				}

			}
		}

		&_clean{

			@include breakPoint(0, $breakPointCompact - 1px){
				display: none;
			}
			.schedule__item-inner{
				opacity: 0;
			}
		}

	}

	&__legend{
		display: none;

		@include breakPoint($breakPointCompact){
			display: block;
			font-size: 12px;
			margin-top: 50px;
		}


		&-item{
			display: inline-block;
			vertical-align: middle;
		}

		&-color{
			display: inline-block;
			vertical-align: middle;
			width: 4px;
			height: 13px;
			background-color: currentColor;
			margin-right: 8px;
		}

		&-name{
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;
		}
	}


	&__diary{

		overflow: hidden;
		margin-bottom: 35px;

		&-inner{
			white-space: nowrap;
			overflow-x: auto;
			margin-bottom: -20px;
			padding-bottom: 20px;
		}

		&-ico{
			display: inline-block;
			vertical-align: bottom;
			padding: 7px 25px 10px 0;
			border-right: 2px solid $colorLight;
			margin-right: 40px;

			@include breakPoint($breakPointCompact){
				padding: 2px 16px 7px 0;
				margin-right: 20px;
			}

			i{
				display: block;
				width: 50px;
				height: 50px;
				background: url("/img/ico/ico_calendar.svg") no-repeat 50% 50%;
				background-size: cover;

				@include breakPoint($breakPointCompact){
					width: 25px;
					height: 25px;
				}
			}
		}
		&-item{
			display: inline-block;
			vertical-align: bottom;
			margin-right: 50px;

			@include breakPoint($breakPointCompact){
				margin-right: 60px;
			}

			&.is-active{
				color: $colorRed;
			}
		}
		&-date{
			font-size: 20px;
			color: $colorGrey;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
			}
		}
		&-name{
			font-size: 30px;
			font-weight: 500;

			@include breakPoint($breakPointCompact){
				font-size: 18px;
			}
		}
	}

	.slick-track{
		margin: 0;
	}

	&__days{
		//margin: 0 -11px;
		//

		@include breakPoint($breakPointCompact){
		//	margin: 0 -17px;
			overflow: hidden;

		}
		//
		@include breakPoint($breakPointWide){
		//	margin: 0 -11px;
		}

		&-item{
			text-align: center;
		}

		&-name{
			font-size: 24px;

			@include breakPoint($breakPointCompact){
				font-size: 16px;
			}
		}

		&-date{
			font-size: 24px;
			color: $colorGrey;
			display: none;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
				display: block;
			}

			.schedule__days-item_current &{
				color: $colorRed;
			}
		}
	}


	&_days{
		margin-top: -120px;
		padding-bottom: 50px;

		@include breakPoint($breakPointCompact){
			padding-bottom: 10px;

			.slider-control__arrow{
				top: 10px;
			}
		}
	}


	&_all{
		.schedule__period{
			top: 10px;
		}
	}
}