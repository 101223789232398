
.clubmap{

	@include user-select();

	.inner{

		@include breakPoint($breakPointCompact){
			width: auto;
			margin: 0 55px 0 70px;
		}
	}

	&__item{

		@include breakPoint($breakPointCompact){
			width: 50%;
			float: left;
			@include box-sizing();

			&:first-child{
				border-right: 1px solid $colorBorder;
			}

			&:hover{

				.clubmap__item-body{
					background: $colorBlue;
				}
				.clubmap__item-map{
					opacity: 0.5;
				}
			}
		}

		&-head{
			padding: 45px 0;

			@include breakPoint($breakPointCompact){
				padding: 15px 0;
			}
		}

		&-title{
			font-size: 36px;
			font-weight: 500;
			margin-bottom: 35px;

			@include breakPoint($breakPointCompact){
				font-size: 30px;
				float: left;
				margin: 4px 0 0 0;
			}
		}

		&-goto{

			@include breakPoint($breakPointCompact){
				float: right;
			}
		}

		&-body{
			background: #dfdfdf;
			padding: 265px 0 30px;
			position: relative;

			@include breakPoint($breakPointCompact){
				padding: 115px 0 70px;

				.inner{
					display: inline-block;
					margin-right: 0;
				}
			}
		}

		&-map{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		&-info{
			background: #fff;
			@include border-radius(4px);

			@include breakPoint($breakPointCompact){
				width: 360px;
			}

			&-about{
				padding: 40px 40px 20px;

				@include breakPoint($breakPointCompact){
					padding: 30px 20px 20px;
				}
			}

			&-title{

				padding: 10px 0 10px 85px;
				position: relative;
				font-size: 24px;
				font-weight: 500;
				min-height: 50px;

				@include breakPoint($breakPointCompact){
					font-size: 20px;
				}

				img{
					position: absolute;
					width: 70px;
					height: 70px;
					top: -13px;
					left: 0;
				}
			}

			&-contact{
				display: table;
				width: 100%;
				font-size: 24px;

				@include breakPoint($breakPointCompact){
					font-size: 13px;
				}

				&-name{
					display: table-cell;
					color: $colorGrey;
					padding: 5px 10px 5px 0;
				}

				&-val{
					display: table-cell;
					text-align: right;
					padding: 5px 0 5px 10px;

					@include breakPoint($breakPointCompact){
						font-size: 14px;
					}
				}
			}
		}

		&-menu{

			display: table;
			width: 100%;
			text-align: center;
			color: $colorGrey;
			font-size: 20px;

			@include breakPoint($breakPointCompact){
				font-size: 12px;
			}

			&-item{
				display: table-cell;
				width: (100% / 3);
				border-top: 1px solid $colorBorder;
				border-left: 1px solid $colorBorder;
				padding: 15px 0 20px;
				color: inherit;

				&:first-child{
					border-left: none;
				}

				&:hover{
					color: $colorBlue;

					.clubmap__item-menu-ico{
						background-position: 0 100%;
					}

				}
			}

			&-ico{

				width: 120px;
				height: 80px;
				background: no-repeat 0 0;
				background-size: 100% auto;
				margin: 0 auto;

				@include breakPoint($breakPointCompact){
					width: 81px;
					height: 54px;
				}

				&_time{
					background-image: url("../img/ico/maps-menu_time.svg");
				}

				&_trainer{
					background-image: url("../img/ico/maps-menu_trainer.svg");
				}

				&_360{
					background-image: url("../img/ico/maps-menu_360.svg");
				}
			}

			&-text{
			}
		}

	}
}