.section_education{

	h3{
		clear: both;
	}
}

.education{

	&-item{
		margin-bottom: 40px;
	}

	&-place{
		font-size: 20px;
		color: $colorGrey;

		@include breakPoint($breakPointCompact){
			font-size: 12px;
			margin-left: 15px;
		}
	}

	&-date{
		display: inline-block;
		padding-right: 13px;
		margin-right: 15px;
		border-right: 1px solid $colorGrey;

		@include breakPoint($breakPointCompact){
			float: left;
			display: block;
			min-width: 40px;
		}
	}

	&-school{
		br{
			display: inline-block;

			@include breakPoint($breakPointCompact){
				display: block;
			}
		}


		@include breakPoint($breakPointCompact){
			margin-left: 70px;
			display: block;
		}
	}



	ul{
		margin-bottom: 15px;

		li{
			&:before{
				background: $colorRed;
			}
		}
	}



	@include breakPoint($breakPointCompact){
		.is-more-hidden{
			display: block;
		}

		.more{
			display: none;
		}

		float: left;
		width: 50%;
		@include box-sizing();
		padding-right: 16px;
	}

	@include breakPoint($breakPointWide){
		padding-right: 32px;
	}
}