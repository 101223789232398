

.achievements{

	&__list{
		margin: -11px;
		font-size: 0;

		@include breakPoint($breakPointCompact){
			margin: 0 -10px 45px;
		}
	}

	&__item{

		@include box-sizing();
		display: inline-block;
		width: 100%;
		padding: 11px;
		vertical-align: top;

		@include breakPoint($breakPointCompact){
			padding: 10px;
			width: 380px;
		}

		&-inner{
			background: #fff;
			@include border-radius(8px);
			padding: 30px;
			height: 205px;
			@include box-sizing();

			@include breakPoint($breakPointCompact){
				@include border-radius(4px);
				padding: 13px 20px;
				height: 154px;
			}
		}

		&-ico{
			height: 50px;
			font-size: 0;
			margin-bottom: 15px;

			@include breakPoint($breakPointCompact){
				height: 28px;
				margin-bottom: 12px;
			}

			img{
				display: inline-block;
				vertical-align: middle;
				height: 100%;
			}
		}

		&-title{
			font-size: 36px;
			height: 85px;
			overflow: hidden;

			@include breakPoint($breakPointCompact){
				font-size: 20px;
				height: 50px;
			}
		}

		&-text{
			font-size: 22px;
			color: $colorGrey;
			margin-top: 5px;
			display: none;

			@include breakPoint($breakPointCompact){
				font-size: 12px;
				display: block;
			}
		}
	}
}