@import "scss/var";
@import "scss/mixins";
@import "scss/fonts";
@import "scss/print";
@import "scss/normalize";


@import "../../node_modules/slick-carousel/slick/slick.scss";
//@import "../../node_modules/lightbox2/src/css/lightbox";
@import "../../node_modules/viewerjs/src/css/viewer";

.viewer-list > li{
	width: 50px;
}

.viewer{
	&-zoom-in::before,
	&-zoom-out::before,
	&-one-to-one::before,
	&-reset::before,
	&-prev::before,
	&-play::before,
	&-next::before,
	&-rotate-left::before,
	&-rotate-right::before,
	&-flip-horizontal::before,
	&-flip-vertical::before,
	&-fullscreen::before,
	&-fullscreen-exit::before,
	&-close::before {
		background-image: none;
	}

	&-canvas{
		.slider-control{
			display: block;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			margin: 0 auto;


			&__close{
				margin-top: -80px;
				margin-right: -11px;

				@include breakPoint($breakPointCompact){
					margin-top: -50px;
					margin-right: -50px;
				}
			}

			&__arrow{
				top: 50%;
				margin-top: -35px;

				@include breakPoint($breakPointCompact){
					margin-top: -22px;
				}

				&_prev{
					margin-left: 0px;
					left: 0;

					@include breakPoint($breakPointCompact){
						margin-left: -50px;
					}
				}

				&_next{
					margin-right: 0px;
					right: 0;

					@include breakPoint($breakPointCompact){
						margin-right: -50px;
					}
				}
			}
		}
	}
}



.viewer-toolbar{
	position: absolute;
	top: 0;
}

.slider-control{
	position: relative;
	margin: 50px 0 0;
	//display: none;
	@include user-select();

	&__close{
		width: 70px;
		height: 70px;
		position: absolute;
		cursor: pointer;
		top: 0px;
		right: 0px;

		@include breakPoint($breakPointCompact){
			width: 44px;
			height: 44px;
		}

		&:before,
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			background: $colorBorder;
			height: 7px;
			width: 70px;
			margin-top: -4px;
			margin-left: -35px;
			border-radius: 10px;

			@include breakPoint($breakPointCompact){
				height: 4px;
				width: 44px;
				margin-top: -2px;
				margin-left: -22px;
			}

		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}

		&:hover{
			&:before,
			&:after {
				background-color: $colorRed;
			}
		}
	}

	&__count{
		width: 100%;
		text-align: center;
		font-size: 48px;
		font-weight: 500;
		color: $colorGrey;

		@include breakPoint($breakPointCompact){
			font-size: 24px;
		}
	}

	&__dots{
		display: none;
	}

	&__arrow{
		width: 70px;
		height: 70px;
		position: absolute;
		cursor: pointer;
		background: url("../img/ico/arrows.svg") no-repeat 0 0;
		background-size: auto 100%;
		z-index: 1;
		top: 0;

		@include breakPoint($breakPointCompact){
			width: 40px;
			height: 40px;


			.slider-control_aside &{
				position: absolute!important;
				top: 50%;
				margin-top: -20px;
			}
		}

		&_prev{
			background-position: 0 0;
			left: 50px;


			&:hover{
				background-position: (200% / 3) 0;
			}

			@include breakPoint($breakPointCompact){
				left: 0px;

				.slider-control_aside &{
					left: -90px;
				}
			}
		}

		&_next{
			background-position: (100% / 3) 0;
			right: 50px;


			&:hover{
				background-position: 100% 0;
			}

			@include breakPoint($breakPointCompact){
				right: 0px;

				.slider-control_aside &{
					right: -90px;
				}
			}
		}
	}

	@include breakPoint($breakPointCompact){
		display: none;
	}


	&_aside{

		@include breakPoint($breakPointCompact){
			position: static!important;
			padding: 0;
			margin: 0;
		}
	}
}


.slick{

	&-list{
		overflow: visible!important;
	}

	&-disabled{
		cursor: default;
		opacity: .3;
	}

	&-overflow{

		@include breakPoint($breakPointCompact){
			overflow: hidden!important;
		}
	}
}



@import "scss/form";
@import "scss/header";
@import "scss/footer";
@import "scss/popup";
@import "scss/nav";
@import "scss/main";



[data-tab-target]{
	display: none;

	&.is-active{
		display: block;
	}
}

.is-more-hidden{
	display: none;
}


.on-mb,
.no-dt{
	@include breakPoint($breakPointCompact){
		display: none!important;
	}
}

.on-dt,
.no-mb{
	@include breakPoint(0, $breakPointCompact - 1px){
		display: none!important;
	}
}

.on-pp{
	display: none;

	.popup &{
		display: block;
	}
}


.is-hidden{
	display: none!important;
}