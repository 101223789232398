.nav{

	position: fixed;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	background: $colorBlue;
	z-index: 11	;
	@include transition('left');

	.is-menu-open &{
		left: 0;
	}

	&__handle{
		@include box-sizing();
		height: $headerHeightMobile;
		width: $headerHeightMobile;
		background: $colorBlue;
		position: absolute;
		top: 0;
		right: -$headerHeightMobile;
		border-bottom: 1px solid rgba($colorBlue, 0.5);
		margin-left:0;
		@include transition('right');
		z-index: 2;

		@include burgerButton('.is-menu-open', 44px, 6px);

		@include breakPoint($breakPointCompact){
			height: $headerHeightDesktop;
			width: $headerHeightDesktop;
			right: -$headerHeightDesktop;
			@include burgerButton('.is-menu-open', 27px, 4px);
		}

		.is-menu-open &{
			right: 0;
		}
	}





	&__inner{
		padding: 20px;
		width: 100%;
		overflow: auto;
		height: 100%;
		@include box-sizing();

		@include breakPoint($breakPointCompact){
			padding: 28px 85px 28px 43px;
		}
	}

	&__logo{
		width: 324px;
		height: 101px;
		margin: 0 20px 60px;

		@include breakPoint($breakPointCompact){
			width: 180px;
			height: 56px;
			margin: 0 0 85px 0;
		}

		img{
			display: block;
			width: 100%;
			height: 100%;
			margin: 0;
		}
	}


	&__menu{

		font-size: 48px;
		font-weight: 500;
		color: #fff;

		a{
			color: inherit;
		}

		@include breakPoint($breakPointCompact){
			font-size: 24px;
			float: left;
		}

		&-item{
			margin: 40px 20px;

			@include breakPoint($breakPointCompact){
				margin: 0 0 15px 0;
			}
		}
	}


	&__object{

		@include breakPoint($breakPointCompact){
			margin-left: 290px;
		}

		&-item{
			background: #fff;
			@include border-radius(4px);
			padding: 45px;
			margin-bottom: 45px;

			@include breakPoint($breakPointCompact){
				padding: 30px 43px;
				margin-bottom: 30px;
				overflow: hidden;

				&:last-child{
					margin-bottom: 0;
				}
			}

			&:nth-of-type(1){

				.nav__object-title:before{
					@include backgroundSprite(214, 0);

					@include breakPoint($breakPointCompact){
						@include backgroundSprite(214, 102);
					}
				}
			}

			&:nth-of-type(2){

				.nav__object-title:before{
					@include backgroundSprite(293, 0);

					@include breakPoint($breakPointCompact){
						@include backgroundSprite(293, 102);
					}
				}
			}
		}

		&-title{
			font-size: 36px;
			font-weight: 500;
			padding-left: 85px;
			position: relative;
			margin-bottom: 35px;

			@include breakPoint($breakPointCompact){
				font-size: 24px;
				padding-left: 50px;
				float: left;
			}

			&:before{
				content: '';
				display: block;
				width: 60px;
				height: 57px;
				top: -12px;
				left: 0;
				position: absolute;

				@include breakPoint($breakPointCompact){
					width: 35px;
					height: 34px;
					top: -8px;
				}
			}
		}

		&-btn{
			margin: 45px 0 20px;

			@include breakPoint($breakPointCompact){
				width: 185px;
				float: right;
				margin: 0;
			}

			.btn{
				display: block;
			}
		}

		&-menu{
			margin-bottom: 60px;

			@include breakPoint($breakPointCompact){
				float: left;
				width: 240px;
				margin: 0;
			}

			@include breakPoint($breakPointWide){
				width: 615px;
			}

			&:after{
				content: '';
				display: block;
				position: relative;
				height: 1px;
				background: $colorBorder;
				margin: 0 -45px;

				@include breakPoint($breakPointCompact){
					display: none;
				}
			}

			&-title{
				color: $colorGrey;
				padding: 30px 0;
				position: relative;

				@include breakPoint($breakPointCompact){
					display: none;
				}

				&:before,
				&:after{
					content: '';
					width: 4px;
					height: 26px;
					background: #b7b7b7;
					position: absolute;
					top: 50%;
					margin-top: -13px;
					margin-right: -2px;
					right: 15px;
					@include transition();
					@include border-radius(10px);

					@include breakPoint($breakPointCompact){
						display: none;
					}
				}

				&:before{
					@include transform(rotate(-135deg));
					margin-right: -8px;
				}

				&:after{
					@include transform(rotate(135deg));
					margin-right: 8px;
				}

			}

			&.is-open{
				.nav__object-menu-list{
					display: block;
				}

				.nav__object-menu-title{
					&:before{
						@include transform(rotate(-45deg));
						margin-right: -8px;
					}

					&:after{
						@include transform(rotate(45deg));
						margin-right: 8px;
					}
				}
			}


			&-list{
				display: none;
				padding: 25px 0 35px;

				@include breakPoint($breakPointCompact){
					display: block;
					padding: 0;
				}
			}

			&-item{
				font-weight: 500;
				margin-bottom: 50px;
				display: block;

				&:last-child{
					margin-bottom: 0;
				}

				@include breakPoint($breakPointCompact){
					display: inline-block;
					margin: 0 20px 8px 0;
					white-space: nowrap;
				}

				@include breakPoint($breakPointWide){
					display: block;
				}

				&.is-active,
				&:hover{
					color: $colorBlue;
				}
			}
		}

		&-contact{

			padding-left: 65px;
			position: relative;
			line-height: 1.83;
			padding-right: 40px;

			@include breakPoint($breakPointCompact){
				margin-left: 350px;
				padding-left: 50px;
				line-height: 1.5;
				margin-bottom: 20px;
			}

			@include breakPoint($breakPointWide){
				margin-left: 615px;
				line-height: 1.83;
				margin-bottom: 10px;
			}

			&:before{
				content: '';
				width: 50px;
				height: 50px;
				@include backgroundSprite(76, 71);
				position: absolute;
				top: -2px;
				left: 0;

				@include breakPoint($breakPointCompact){
					width: 28px;
					height: 28px;
					top: -4px;
					@include backgroundSprite(134, 85);
				}

				@include breakPoint($breakPointWide){
					top: 2px;
				}
			}

			&:after{
				content: '';
				width: 35px;
				height: 35px;
				position: absolute;
				cursor: pointer;
				background: url("../img/ico/arrows.svg") no-repeat 100% 0;
				background-size: auto 100%;
				z-index: 1;
				top: 3px;
				right: 0;

				@include breakPoint($breakPointCompact){
					display: none;
				}
			}

			&-bigtitle{
				display: none;

				@include breakPoint($breakPointWide){
					display: block;
					font-size: 18px;
					font-weight: 500;
				}
			}

			&-title{
				font-size: 24px;

				@include breakPoint($breakPointCompact){
					font-size: 14px;
				}
			}
			&-phone{
				font-weight: 500;
				color: $colorRed;

				span{
					display: block;
					@include breakPoint($breakPointCompact){
						display: inline;

						&:after{
							content: ', ';
						}

						&:last-child{
							&:after{
								display: none;
							}
						}
					}
				}

			}
		}

		&-address{
			padding-left: 65px;
			font-size: 24px;
			position: relative;
			line-height: 1.83;
			padding-right: 40px;

			@include breakPoint($breakPointCompact){
				font-size: 14px;
				margin-left: 350px;
				padding-left: 50px;
				line-height: 1.5;
			}

			@include breakPoint($breakPointWide){
				margin-left: 615px;
				line-height: 1.83;
			}

			&:before{
				content: '';
				width: 50px;
				height: 50px;
				@include backgroundSprite(76, 5);
				position: absolute;
				top: -5px;
				left: 0px;

				@include breakPoint($breakPointCompact){
					width: 27px;
					height: 34px;
					top: -10px;
					@include backgroundSprite(133, 21);
				}

				@include breakPoint($breakPointWide){
					top: -5px;
				}
			}

			&:after{
				content: '';
				width: 35px;
				height: 35px;
				position: absolute;
				cursor: pointer;
				background: url("../img/ico/arrows.svg") no-repeat 100% 0;
				background-size: auto 100%;
				z-index: 1;
				top: 3px;
				right: 0;

				@include breakPoint($breakPointCompact){
					display: none;
				}
			}

			&-bigtitle{
				display: none;

				@include breakPoint($breakPointWide){
					display: block;
					font-size: 18px;
					font-weight: 500;

				}
			}
		}

		&-socials{
			padding-left: 65px;
			margin-top: 30px;

			@include breakPoint($breakPointCompact){
				margin-left: 350px;
				padding-left: 50px;
				margin-top: 15px;
			}

			@include breakPoint($breakPointWide){
				margin-left: 615px;
			}

			&-list{}

			&-item{
				width: 48px;
				height: 48px;
				display: inline-block;
				vertical-align: middle;
				background: url("../img/ico/ico-soc.svg") no-repeat 0 0;
				background-size: 100% auto;
				margin-right: 70px;

				@include breakPoint($breakPointCompact){
					width: 25px;
					height: 25px;
					margin-right: 20px;
				}

				&:last-child{
					margin-right: 0;
				}

				&:last-child{
					margin-right: 0;
				}

				&_vk{
					background-position: 0 0;
				}
				&_fb{
					background-position: 0 33.33333%;
				}
				&_yt{
					background-position: 0 66.66666%;
				}
				&_ig{
					background-position: 0 100%;
				}
			}
		}
	}


}
